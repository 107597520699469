import { strings } from "./strings";

export const menu = () => [
    {
        name: "home",
        text: strings.ourGamesHeader,
        link: "/#games"
    },
    {
        name: "about",
        text: strings.aboutHeader,
        link: "/#about"
    },
    {
        name: "publishing",
        text: strings.publishingHeader,
        link: "/#publishing"
    },
    {
        name: "careers",
        text: strings.careersHeader,
        link: "/#careers"
    },
    {
        name: "contacts",
        text: strings.contactsHeader,
        link: "/#contacts"
    }
]
