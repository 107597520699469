import { useCallback, useContext } from "react";
import { Dropdown, NavLink } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { languages } from "../../constants/strings";
import { LanguageContext } from "./LanguageContext";

type LanguagesDropdownProps = {
    className?: string;
};

const alignHack = { right: 0, left: "auto" }; // bootstrap 5 styles doesn't compatible with bootstrap 4 scripts here

export const LanguagesDropdown = ({ className }: LanguagesDropdownProps) => {
    const { language: currentLanguage, setLanguage } = useContext(LanguageContext)
    const onSelect = useCallback((key: string | null) => { if (key) setLanguage(key) }, [setLanguage])
    return (
        <Dropdown className={className} align="end" onSelect={onSelect}>
            <Dropdown.Toggle id="language" as={NavLink}>
                <FontAwesomeIcon icon={faGlobe} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-dark" style={alignHack}>
                {Object.keys(languages).sort().map(language => {
                    return (
                        <Dropdown.Item
                            key={language}
                            active={language === currentLanguage}
                            eventKey={language}
                        >
                            {languages[language as keyof typeof languages]}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
        </Dropdown>
    )
}
