import React from "react"
import { Link } from "react-router-dom"
import { strings } from "../../constants/strings"

export const Footer = () => {
    return (
        <footer className="bg-dark mt-auto py-3">
            <div className="container">
                <div className="text-center">
                    © Euphoria Games 2019-2024 | <Link to="/privacy" className="text-nowrap">{strings.privacyLink}</Link>
                </div>
            </div>
        </footer>
    )
}
