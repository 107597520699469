import React from 'react'

type Props = {
    className?: string,
    children: React.ReactNode
}

export const SectionHeader = ({ children }: Props) => {
    return (
        <h1 className="display-2 text-center text-uppercase">{children}</h1>
    )
}
