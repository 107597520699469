import { Layout } from '../../components/layout/Layout'

export const PaymentSuccessPage = () => {
    return (
        <Layout>
            <div className="container container my-5 d-flex flex-grow-1 flex-row">
                <div className="m-auto">Платеж выполнен! Можете закрыть это окно и вернуться в игру</div>
            </div>
        </Layout>
    )
}