import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { logEvent } from "firebase/analytics"
import { analytics } from '../../utils/firebase';


export const CurrentScreenObserver = () => {
    const location = useLocation()
    useEffect(() => {
        const title = (location.hash && location.hash.substring(1)) ||
            (location.pathname.length > 1 && location.pathname.substring(location.pathname.lastIndexOf("/") + 1)) ||
            ("home")
        logEvent(analytics, "page_view", {
            page_path: location.pathname + location.hash,
            page_title: title
        })
    }, [location])
    return null
}
