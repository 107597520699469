import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Link } from 'react-router-dom';
import { Anchor } from '../components/common/Anchor';
import { SectionHeader } from '../components/common/SectionHeader';
import { SocialLinks } from '../components/common/SocialLinks';
import { strings } from '../constants/strings';

export const ContactsSection = () => {
    return (
        <section className="z-index-1">
            <Anchor id="contacts" />
            <div className="container my-5">
                <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce>
                    <SectionHeader>{strings.contactsHeader}</SectionHeader>
                    <div className="row my-3 text-center">
                        <div className="col-md-6 offset-md-3">
                            <h1>E-mail</h1>
                            <div>
                                <Link to="mailto:info@euphoriagames.ru">info@euphoriagames.ru</Link>
                            </div>
                        </div>
                    </div>
                    <div className="row my-3 text-center">
                        <div className="col-md-6 offset-md-3">
                            <h1>{strings.followUsHeader}</h1>
                            <div className="fs-1">
                                <SocialLinks />
                            </div>
                        </div>
                    </div>
                </AnimationOnScroll>
            </div>
        </section>
    )
}
