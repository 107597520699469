import { AnimationOnScroll } from 'react-animation-on-scroll';
import { ArrowDownLink } from '../components/common/ArrowDownLink';
import { ImageLink } from '../components/common/ImageLink';
import { services } from '../constants/services';
import { menu } from '../constants/menu';
import { projects } from '../constants/projects';
import video from '../assets/video.mp4';

export const PromoSection = () => {
    var project = projects().find(project => project.title === 'Mimicry')
    return (
        <div className="d-flex position-relative overflow-hidden align-items-start justify-content-center body-height">
        <video autoPlay muted loop playsInline className="position-absolute min-width-100 min-height-100" poster="">
            <source type="video/mp4" src={video} />
        </video>
            <div className="container position-absolute d-flex flex-column justify-content-center top-0 end-0 bottom-0 start-0">
                <div className="d-inline-flex flex-column align-items-center mt-auto" style={{ marginBottom: "8rem" }}>
                    <AnimationOnScroll animateIn="animate__fadeIn" animateOnce className="row justify-content-center">
                        <PromoLinkColumn href={project!.links.stores!.googlePlay!} image={services.googlePlay.download} name={services.googlePlay.name} />
                        <PromoLinkColumn href={project!.links.stores!.appStore!} image={services.appStore.download} name={services.appStore.name} />
                        <PromoLinkColumn href={project!.links.stores!.steam!} image={services.steam.registration} name={services.steam.name} />
                    </AnimationOnScroll>
                </div>
            </div>
            <ArrowDownLink className="position-absolute bottom-0 start-0 end-0 pb-5 z-index-1" href={menu()[0].link} />
            <div className="video-bottom-gradient"></div>
        </div>
    )
}


type ServiceRegistrationLinkProps = {
    name: string,
    image: string,
    href: string
}

const PromoLinkColumn = ({ name, image, href }: ServiceRegistrationLinkProps) => {
    return (
        <div className="col-md-3 mt-3 text-center">
            <ImageLink
                type={name}
                href={href}
                image={image}
                className="max-w-50 max-w-md-100"
            />
        </div>
    )
}
