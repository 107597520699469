import React from "react";
import Nav from "react-bootstrap/esm/Nav";
import Navbar from "react-bootstrap/esm/Navbar";
import cn from 'classnames';

import { menu } from "../../constants/menu";
import { SocialLinks } from "../common/SocialLinks";
import { LanguagesDropdown } from "../common/LanguagesDropdown";

type Props = {
    logoHidden?: boolean,
};

export const Header = ({ logoHidden }: Props) => {
    return (
        <Navbar collapseOnSelect bg="dark" variant="dark" expand="xl" fixed="top">
            <div className="container">
                <Navbar.Brand href="/" className={cn("d-flex align-items-center transition-all-ease overflow-hidden", logoHidden ? "max-w-100 max-w-sm-0" : "max-w-100")}>
                    <img className="d-inline-block align-top" src="/logo.png" style={{ height: "50px" }} alt="Logo" />
                    <h4 className="mx-3 my-0">Euphoria Games</h4>
                </Navbar.Brand>
                <div className="fs-3 ms-auto me-3 col-12 col-sm-auto order-1 order-sm-0">
                    <SocialLinks />
                </div>
                <Navbar.Toggle aria-controls="navbar-top" />
                <Navbar.Collapse id="navbar-top">
                    <Nav className="ms-auto">
                        {menu().map(item => {
                            return (
                                <Nav.Link key={item.name} className="ms-3 py-2" href={item.link}>{item.text}</Nav.Link>
                            );
                        })}
                        <LanguagesDropdown className="ms-3" />
                    </Nav>
                </Navbar.Collapse>
            </div>
        </Navbar>
    );
};
