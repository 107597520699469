import React from 'react';

type Props = {
    to: string,
    children: React.ReactNode
}

export const ButtonLink = ({ to, children }: Props) => {
    return (
        <a className="btn btn-primary rounded-pill text-uppercase fw-bold px-3 py-2 letter-spacing-large btn-animated" href={to}>{children}</a>
    );
};
