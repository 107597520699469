import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Anchor } from '../components/common/Anchor';
import { SectionHeader } from '../components/common/SectionHeader';
import { PublishingFeatures } from '../components/PublishingFeatures';
import { strings } from '../constants/strings';

export const PublishingSection = () => {
    return (
        <section>
            <Anchor id="publishing" />
            <div className="container my-5">
                <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce>
                    <SectionHeader>{strings.publishingHeader}</SectionHeader>
                    <div className="row">
                        <div className="col-md-6 offset-md-3">
                            {strings.publishing.map(text => <p key={text}>{text}</p>)}
                        </div>
                    </div>
                </AnimationOnScroll>
                <PublishingFeatures />
            </div>
        </section>
    );
};
