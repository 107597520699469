import { Layout } from '../../components/layout/Layout'

export const MimicryPrivacyPage = () => {
    return (
        <Layout>
            <div className="container my-5">
                <Policy />
            </div>
        </Layout>
    )
}

const Policy = () => (
    <div style={{ whiteSpace: "pre-wrap" }}>
        {`
Privacy Policy (Updated December 21, 2021)


1. INTRODUCTION
1.1 Welcome to the Euphoria Games website run by Euphoria Games and its affiliates and related corporations (individually and collectively, "Euphoria Games", "we", "us" or "our"). Euphoria Games takes its responsibilities under applicable privacy laws and regulations ("Privacy Laws") seriously and is committed to respecting the privacy rights and concerns of all users of our Euphoria Games games (“Games”) and the services we provide collectively as the ("Services"). We recognize the importance of the personal data you have entrusted to us and believe that it is our responsibility to properly manage, protect and process your personal data. This policy (“Privacy Policy”) is designed to assist you in understanding how we collect, use, disclose and/or process the personal data you have provided to us and/or possess about you, whether now or in the future, as well as to assist you in making an informed decision before providing us with any of your personal data.  This Privacy Policy is also designed to assist our users who live in the European Union (the “EU”) to understand their applicable data privacy rights that exist under the EU's General Data Protection Regulation when they use the Services. Capitalized terms used in this Privacy Policy that are not otherwise defined have the meanings given to them in the Euphoria Games Terms of Service.
 
1.2 "Personal Data" or "personal data" means data, whether true or not, about an individual who can be identified from that data, or from that data and other information to which an organization has or is likely to have access. Common examples of personal data could include name, identification number and contact information.
 
1.3 By using the Services, registering for an account with us, you acknowledge and agree that you accept the practices, requirements, and/or policies outlined in this Privacy Policy, and you hereby consent to us collecting, using, disclosing and/or processing your personal data as described herein. IF YOU DO NOT CONSENT TO THE PROCESSING OF YOUR PERSONAL DATA AS DESCRIBED IN THIS PRIVACY POLICY, PLEASE DO NOT USE OUR SERVICES OR ACCESS OUR GAMES. If we change our Privacy Policy, we will post those changes or the amended Privacy Policy on our social networks so you can read it again. We reserve the right to amend this Privacy Policy at any time.
 
 
2. WHEN WILL Euphoria Games COLLECT PERSONAL DATA?
2.1 We will/may collect personal data about you: 
• when you register and/or use our Services, Games, or open an account with us; 
• when you submit any form, including, but not limited to, application forms or other forms relating to any of our products and services, whether online or by way of a physical form; 
• when you enter into any agreement or provide other documentation or information in respect of your interactions with us, or when you use our products and services;
• when you interact with us, such as via telephone calls (which may be recorded), letters, fax, face-to-face meetings, social media platforms and emails; 
• when you use our electronic services, or interact with us via our Games, contacts or use our Services. This includes, without limitation, through cookies which we may deploy when you interact with our Games;
• when you carry out transactions through our Games or Site;
• when you provide us with feedback or complaints;
• when you register for a contest, for games or game specific events;
• during your gameplay; and
• when you submit your personal data to us for any reason. 
 
The above does not purport to be exhaustive and sets out some common instances of when personal data about you may be collected. 
 
 
3. WHAT PERSONAL DATA WILL Euphoria Games COLLECT?
3.1 The personal data that Euphoria Games may collect includes but is not limited to:
• email address;
• information sent by or associated with the device(s) used to access our Services;
• any other information about the user when the user signs up to use our Services, and when the user uses the Services, as well as information related to how the user uses our Services; and
• aggregate data on content the user engages with.
 
3.2 You agree not to submit any information to us which is inaccurate or misleading, and you agree to inform us of any inaccuracies or changes to such information. We reserve the right at our sole discretion to require further documentation to verify the information provided by you.
 
3.3 If you sign up to be a user of our Services using your social media account (“Social Media Account”), link your account to your Social Media Account or use any Euphoria Games social media features, we may access information about you which you have voluntarily provided to your Social Media Account provider in accordance with such provider's policies, and we will manage and use such personal data in accordance with this Privacy Policy at all times. 
 
3.4 If you do not want us to collect the aforementioned information/personal data, you may opt out at any time by notifying our Data Protection Officer in writing. Further information on opting out can be found in the section below entitled "How can you withdraw consent, remove, request access to or modify information you have provided to us?". Note, however, that opting out or withdrawing your consent for us to collect, use or process your personal data may affect your use of the Services. For example, opting out of the collection of location information will cause its location-based features to be disabled.
 
 
4. COLLECTION OF OTHER DATA
4.1 As with most mobile applications, your device sends information which may include data about you that gets logged by a web server when you use our Services. This typically includes without limitation your device’s Internet Protocol (IP) address, computer/mobile device operating system and browser type, type of mobile device, the characteristics of the mobile device, the unique device identifier (UDID) or mobile equipment identifier (MEID) for your mobile device, the address of a referring website (if any), the pages you visit on our mobile applications and the times of visit. If you are logged in, this information is associated with your personal account. The information is also included in anonymous statistics to allow us to understand how visitors use our Games.
 
4.2 Our mobile applications may collect precise information about the location of your mobile device using technologies such as GPS, Wi-Fi, etc. We collect, use, disclose and/or process this information for one or more purposes including, without limitation, location-based services that you request or to deliver relevant content to you based on your location or to allow you to share your location to other Users as part of the services under our mobile applications. For most mobile devices, you are able to withdraw your permission for us to acquire this information on your location through your device settings. If you have questions about how to disable your mobile device's location services, please contact your mobile device service provider or the device manufacturer.
 
 
5. COOKIES
5.1 We may from time to time implement "cookies" or other features to allow us or third parties to collect or share information that will help us improve our Games and the Services we offer, or help us offer new services and features. “Cookies” are identifiers we transfer to your computer or mobile device that allow us to recognize your computer or device and tell us how and when the Services are used or visited, by how many people and to track activity within our Games. We may link cookie information to personal data. Cookies also link to information regarding what items you have selected for purchase pages you have viewed, or games you have played. This information is used to keep track of your shopping cart, for example. Cookies are also used to deliver content specific to your interest and to monitor usage of the Services.
 
5.2 You may refuse the use of cookies by selecting the appropriate settings on your browser. However, please note that if you do this you may not be able to use the full functionality of our Games or the Services.
 
 
6. HOW DO WE USE THE INFORMATION YOU PROVIDE US?
6.1 We may collect, use, disclose and/or process your personal data for one or more of the following purposes:
(a) to consider and/or process your application/transaction with us or your transactions or communications with third parties via the Services;
(b) to manage, operate, provide and/or administer your use of and/or access to our Services, and Games (including, without limitation, your preferences), as well as your relationship and user account with us;
(c) to respond to, process, deal with or complete a transaction and/or to fulfill your requests for certain products and services and notify you of service issues and unusual account actions;
(d) to enforce our Terms of Service or any applicable end user license agreements;
(e) to protect personal safety and the rights, property or safety of others;
(f) for identification and/or verification;
(g) to maintain and administer any software updates and/or other updates and support that may be required from time to time to ensure the smooth running of our Services; 
(h) to deal with or facilitate customer service, carry out your instructions, deal with or respond to any enquiries given by (or purported to be given by) you or on your behalf;
(i) to contact you or communicate with you via voice call, text message and/or fax message, email and/or postal mail or otherwise for the purposes of administering and/or managing your relationship with us or your use of our Services, such as but not limited to communicating administrative information to you relating to our Services. You acknowledge and agree that such communication by us could be by way of the mailing of correspondence, documents or notices to you, which could involve disclosure of certain personal data about you to bring about delivery of the same as well as on the external cover of envelopes/mail packages; 
(j) to allow other users to interact or connect with you, including to inform you when another user has sent you a private message or posted a comment for you on the Games;
(k) to conduct research, analysis and development activities (including, but not limited to, data analytics, surveys, product and service development and/or profiling), to analyze how you use our Services, to improve our Services or products and/or to enhance your customer experience;
(l) to allow for advertising and other audits and surveys to, among other things, validate the size and composition of our target audience, and understand their experience with Euphoria Games’s Services;
(m) for marketing and, in this regard, to send you by various modes of communication marketing and promotional information and materials relating to products and/or services (including, without limitation, products and/or services of third parties whom Euphoria Games may collaborate or tie up with) that Euphoria Games (and/or its affiliates or related corporations) may be selling, marketing or promoting, whether such products or services exist now or are created in the future. You can unsubscribe from receiving marketing information at any time by using the unsubscribe function within the electronic marketing material. We may use your contact information to send newsletters from us and from our related companies;
(n) to respond to legal processes or to comply with or as required by any applicable law, governmental or regulatory requirements of any relevant jurisdiction, including, without limitation, meeting the requirements to make disclosure under the requirements of any law binding on Euphoria Games or on its related corporations or affiliates;
(o) to produce statistics and research for internal and statutory reporting and/or record-keeping requirements;
(p) to carry out due diligence or other screening activities (including, without limitation, background checks) in accordance with legal or regulatory obligations or our risk management procedures that may be required by law or that may have been put in place by us;
(q) to audit our Services or Euphoria Games's business;
(r) to prevent or investigate any actual or suspected violations of our Terms of Service, fraud, unlawful activity, omission or misconduct, whether relating to your use of our Services or any other matter arising from your relationship with us;
(s) to store, host, back up (whether for disaster recovery or otherwise) of your personal data, whether within or outside of your jurisdiction;
(t) to deal with and/or facilitate a business asset transaction or a potential business asset transaction, where such transaction involves Euphoria Games as a participant or involves only a related corporation or affiliate of Euphoria Games as a participant or involves Euphoria Games and/or any one or more of Euphoria Games's related corporations or affiliates as participant(s), and there may be other third party organizations who are participants in such transaction. A “business asset transaction” refers to the purchase, sale, lease, merger, amalgamation or any other acquisition, disposal or financing of an organization or a portion of an organization or of any of the business or assets of an organization; and/or
(u) any other purposes which we notify you of at the time of obtaining your consent.
(collectively, the “Purposes”).
 
6.2 You acknowledge, consent and agree that Euphoria Games may access, preserve and disclose your Account information and Content if required to do so by law or pursuant to an order of a court or by any governmental or regulatory authority having jurisdiction over Euphoria Games or in a good faith belief that such access preservation or disclosure is reasonably necessary to: (a) comply with legal process; (b) comply with a request from any governmental or regulatory authority having jurisdiction over Euphoria Games, (c) enforce the Euphoria Games Terms of Service or this Privacy Policy; (d) respond to any threatened or actual claims asserted against Euphoria Games or other claim that any Content violates the rights of third parties; (e) respond to your requests for customer service; or (f) protect the rights, property or personal safety of Euphoria Games, its users and/or the public.

6.3 As the purposes for which we will/may collect, use, disclose or process your personal data depend on the circumstances at hand, such purpose may not appear above. However, we will notify you of such other purposes at the time of obtaining your consent, unless processing of the applicable data without your consent is permitted by the Privacy Laws.
 
 
7. HOW DOES Euphoria Games PROTECT AND RETAIN CUSTOMER INFORMATION?
7.1 We implement a variety of security measures and strive to ensure the security of your personal data on our systems. User personal data is contained behind secured networks and is only accessible by a limited number of employees who have special access rights to such systems. However, there can inevitably be no guarantee of absolute security. 

7.2  We will retain personal data in accordance with the Privacy Laws and/or other applicable laws. That is, we will destroy or anonymize your personal data when we have reasonably determined that (i) the purpose for which that personal data was collected is no longer being served by the retention of such personal data; (ii) retention is no longer necessary for any legal or business purposes; and (iii) no other legitimate interest warrant further retention of such personal data. If you cease using our Services, or your permission to use Games and/or the Services is terminated or withdrawn, we may continue storing, using and/or disclosing your personal data in accordance with this Privacy Policy and our obligations under the Privacy Laws. Subject to applicable law, we may securely dispose of your personal data without prior notice to you.
 
 
8. DOES Euphoria Games DISCLOSE THE INFORMATION IT COLLECTS FROM ITS VISITORS TO OUTSIDE PARTIES?
8.1 In conducting our business, we will/may need to disclose your personal data to our third party service providers, agents and/or our affiliates or related corporations, and/or other third parties, whether sited in Russia or outside of Russia, for one or more of the above-stated Purposes. Such third party service providers, agents and/or affiliates or related corporations and/or other third parties would be processing your personal data either on our behalf or otherwise, for one or more of the above-stated Purposes. Such third parties include, without limitation:
• our subsidiaries, affiliates and related corporations;
• other users of our Platform for one or more of the above-stated Purposes;
• contractors, agents, service providers and other third parties we use to support our business. These include but are not limited to those which provide administrative or other services to us such as mailing houses, telecommunication companies, information technology companies and data centers;
• a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of Euphoria Games’s assets, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding, in which personal data held by Euphoria Games about our users is among the assets transferred; or to a counterparty in a business asset transaction that Euphoria Games or any of its affiliates or related corporations is involved in; and
• third parties to whom disclosure by us is for one or more of the Purposes and such third parties would in turn be collecting and processing your personal data for one or more of the Purposes.
 
8.2 This may require, among other things, disclosing your gaming history and/or disclosing records showing which games were viewed by which IP addresses. We will also share statistical and demographic information about our users and their use of the Services with suppliers of advertisements and programming. 
 
8.3 Additionally, you may elect to export your user activity feeds as RSS feeds which may be subscribed to by other users, which may include information regarding your game play, installation of games, connections with other users and other information about your account activity which is published as a feed in Games.
 
8.4 For the avoidance of doubt, in the event that Privacy Laws or other applicable laws permit an organization such as us to collect, use or disclose your personal data without your consent, such permission granted by the laws shall continue to apply.
 
8.5 Third parties may unlawfully intercept or access personal data transmitted to or contained on the site, technologies may malfunction or not work as anticipated, or someone might access, abuse or misuse information through no fault of ours. We will nevertheless deploy reasonable security arrangements to protect your personal data as required by the Privacy Laws; however there can inevitably be no guarantee of absolute security such as but not limited to when unauthorized disclosure arises from malicious and sophisticated hacking by malcontents through no fault of ours.
 
 
9. INFORMATION ON CHILDREN
9.1 The Services are not intended for children under the age of 12. We do not knowingly collect or maintain any personal data or non-personally-identifiable information from anyone under the age of 12 nor is any part of our Site, Games or other Services directed to children under the age of 12. As a parent or legal guardian, please do not allow such children under your care to submit personal data to Euphoria Games. In the event that personal data of a child under the age of 12 in your care is disclosed to Euphoria Games, you hereby consent to the processing of the child’s personal data and accept and agree to be bound by this Privacy Policy on behalf of such child. We will close any accounts used exclusively by such children and will remove and/or delete any personal data we believe was submitted without parental consent by any child under the age of 12.
 
 
10. INFORMATION COLLECTED BY THIRD PARTIES
10.1 Our Games use Google Analytics, a web analytics service provided by Google, Inc. ("Google"). Google Analytics uses cookies, which are text files placed on your device, to help the website analyze how users use our Services. The information generated by the cookie about your use of the Services or device (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of our Services, compiling reports on website activity for website operators and providing other services relating to website activity and Internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. Google will not associate your IP address with any other data held by Google. 
 
10.2 We, and third parties, may from time to time make software applications downloads available for your use on or through the Services. These applications may separately access, and allow a third party to view, your identifiable information, such as your name, your User ID, your device’s IP Address or other information such as what game you are playing during any given session, and any cookies that you may previously have installed or that were installed for you by a third party software application or website. Additionally, these applications may ask you to provide additional information directly to third parties. Third party products or services provided through these applications are not owned or controlled by Euphoria Games. You are encouraged to read the terms and other policies published by such third parties on their websites or otherwise. 
 
 
11. DISCLAIMER REGARDING SECURITY AND THIRD PARTY SITES
11.1 WE DO NOT GUARANTEE THE SECURITY OF PERSONAL DATA AND/OR OTHER INFORMATION THAT YOU PROVIDE ON THIRD PARTY SITES. We do implement a variety of security measures to maintain the safety of your personal data that is in our possession or under our control. Your personal data is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the personal data confidential.
 
11.2 In an attempt to provide you with increased value, we may choose various third party websites to link to, and frame within, Games. We may also participate in co-branding and other relationships to offer e-commerce and other services and features to our visitors. These linked sites have separate and independent privacy policies as well as security arrangements. Even if the third party is affiliated with us, we have no control over these linked sites, each of which has separate privacy and data collection practices independent of us. Data collected by our co-brand partners or third party web sites (even if offered on or through our Games) may not be received by us. 
 
11.3 We therefore have no responsibility or liability for the content, security arrangements (or lack thereof) and activities of these linked sites. These linked sites are only for your convenience and you therefore access them at your own risk. Nonetheless, we seek to protect the integrity of our Games and the links placed upon each of them and therefore welcome any feedback about these linked sites (including, without limitation, if a specific link does not work).
 
 
12. WILL Euphoria Games TRANSFER YOUR INFORMATION OVERSEAS? 
12.1 Your personal data and/or information may be transferred to, stored or processed outside of your country. In most cases, your personal data will be processed in Russia, where our servers are located and our central database is operated. If you are a user in Russia, your personal data will be processed and stored in our servers in Russia or outside the country.  Euphoria Games will only transfer your information overseas in accordance with Privacy Laws.  More information about the transfer out of the EU, of personal data and/or information relating to users who are located in the EU, can be found in the section titled "Additional Provisions for EU Users Only".
 
 
13. HOW CAN YOU WITHDRAW CONSENT, REQUEST ACCESS TO OR CORRECT INFORMATION YOU HAVE PROVIDED TO US?
Withdrawing Consent
 
13.1 You may withdraw your consent for the collection, use and/or disclosure of your personal data in our possession or under our control by sending an email to our Personal Data Protection Officer at info@euphoriagames.ru, and we will process such requests in accordance with this Privacy Policy and our obligations under the Privacy Laws and other applicable law. 
 
13.2 However, your withdrawal of consent may mean that we will not be able to continue providing the Services to you, and we may need to terminate your existing relationship and/or the contract you have with us.
 
Requesting Access to, or Correction of Personal Data
 
13.3 If you have an account with us and you want to correct it, please, submit your written request by sending an email to our Personal Data Protection Officer at info@euphoriagames.ru. 
 
13.4 We reserve the right to refuse to correct your personal data in accordance with the provisions as set out in Privacy Laws, where they require and/or entitle an organization to refuse to correct personal data in stated circumstances. 
 
 
14. ADDITIONAL PROVISIONS FOR EU USERS ONLY
14.1 The provisions in this Section only apply if you are a user who is located in the European Union (“EU”). These provisions take precedence over any inconsistent provisions in the remainder of this Privacy Policy.  
 
14.2 Portability
You have the right to obtain from us the Personal Data that you have previously provided to us, and which we process on the basis of your consent in a structured, commonly used and machine-readable format and a right to request that we transfer such Personal Data to another party. If you wish for us to transfer the Personal Data to another party, please ensure you detail that party and note that we can only do so where it is technically feasible. We are not responsible for the security of the Personal Data or its processing once received by the third party. We also may not provide you with certain Personal Data if providing it would interfere with another’s rights (e.g. where providing the Personal Data we hold about you would reveal information about another person). 
 
14.3 Erasure
You may request that we erase the Personal Data we hold about you in the following circumstances:
(a) you believe that it is no longer necessary for us to hold the Personal Data we hold about you;
(b) we are processing the Personal Data we hold about you on the basis of your consent, and you wish to withdraw your consent and there is no other ground under which we can process the Personal Data;
(c) we are processing the Personal Data we hold about you on the basis of our legitimate interest and you object to such processing.  Please provide us with detail as to your reasoning so that we can assess whether there is an overriding interest for us to process and retain such Personal Data;
(d) you no longer wish us to use the Personal Data we hold about you in order to send you promotions, special offers, marketing and lucky draws; or 
(e) you believe the Personal Data we hold about you is being unlawfully processed by us.
 
Please provide as much detail as possible on your reasons for the request to assist us in determining whether you have a valid basis for erasure. However, we may retain the Personal Data if there are valid grounds under law for us to do so (e.g., for the defense of legal claims or freedom of expression) but we will let you know if that is the case.  Please note that after deleting the Personal Data, we may not be able to provide the same level of servicing to you as we will not be aware of your preferences.
 
14.4 Retention of Personal Data
We retain your Personal Data as long as needed to provide services or products to you, or as required or permitted by applicable laws, such as tax and accounting laws. If you make a request for erasure of your Personal Data 3 and we determine that there is a valid basis for your request, we will endeavor to erase the Personal Data within 30 days of your request, or inform you if it will take longer.
 
14.5 Objection against Profiling and Targeted Marketing
At any time you have the right to object to our processing of Personal Data about you in order to send you promotions, special offers and marketing messages, including where we build profiles for such purposes and we will stop processing the Personal Data for that purpose. Please provide as much detail as possible on your reasons for the request to assist us in determining whether you have a valid basis for objection. However, we may retain the Personal Data if there are valid grounds under law for us to do so.
 
14.6 Restriction of Processing to Storage Only
You have a right to request that we restrict the processing of the Personal Data we hold about you to storage purposes only, where:   
(a) you believe the Personal Data is not accurate, for the period it takes for us to verify whether the Personal Data is accurate;
(b) we wish to erase the Personal Data but you want us to just store it instead;
(c) we wish to erase the Personal Data as it is no longer necessary for our purposes but you require it to be stored for the establishment, exercise or defense of legal claims; or
(d) you have objected to us processing Personal Data we hold about you on the basis of our legitimate interest and you wish us to stop processing the Personal Data whilst we determine whether there is an overriding interest in us processing and retaining such Personal Data.
 
14.7 Transfer of Personal Data outside the EU
Your Personal Data may be transferred outside of the EU.  We take appropriate steps to ensure that recipients of your Personal Data are bound to duties of confidentiality and we implement measures such as appropriate contractual clauses to ensure that the recipients of such transfer protect and treat your Personal Data in accordance with all applicable personal data protection laws. 
 
14.8   Information on Children
The Services are not intended for children under the age of 12 who are located in the EU. We do not knowingly collect or maintain any personal data or non-personally-identifiable information from anyone under the age of 12 who is located in the EU, nor is any part of our Site, Games or other Services directed to children under the age of 12 who are located in the EU. We will close any accounts used exclusively by such children and will remove and/or delete any personal data we believe was submitted by any child under the age of 12 who is located in the EU.
 
 
15. QUESTIONS, CONCERNS OR COMPLAINTS? CONTACT US
15.1 If you have any questions, concerns or complaints about our privacy practices, please contact us at info@euphoriagames.ru.
        `}
    </div>
)
