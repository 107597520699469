import { Layout } from '../components/layout/Layout';
import { Link } from 'react-router-dom';
import { SocialLinks } from '../components/common/SocialLinks';
import { strings } from '../constants/strings';

export const SupportPage = () => {
    return (
        <Layout>
            <section className="z-index-1">
                <div className="container my-5">
                    <div className="row my-3 text-center">
                        <div className="col-md-6 offset-md-3">
                            <h1>{strings.supportTitle}</h1>
                            <p>{strings.supportText}</p>
                        </div>
                    </div>
                    <div className="row my-3 text-center">
                        <div className="col-md-6 offset-md-3">
                            <div>
                                E-mail: <Link to="mailto:euphoriagamesrussia@gmail.com">euphoriagamesrussia@gmail.com</Link>
                            </div>
                        </div>
                    </div>
                    <div className="row my-3 text-center">
                        <div className="col-md-6 offset-md-3">
                            <div className="fs-1">
                                <SocialLinks />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
