import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const PrivacyPolicy = () => (
    <Container className="p-5">
        <Row>
            <Col>
                <h4>Privacy Policy (Updated 03/03/2021)</h4>
                <hr />
            </Col>
        </Row>
        <Row>
            <Col>
                <center>
                    <h5>Euphoria Horror Games (Nazarov Anton, Gusakov Anatolii)</h5>
                </center>

                <p>The following Privacy Policy applies to all our games, directed to all audiences and any ages, distributed by
			Euphoria Games (Euphoria Horror Games) with the following founders: Nazarov Anton (Назаров Антон Сергеевич), Gusakov Anatolii (Гусаков Анатолий Николаевич) in the following platforms:</p>

                <p>• Google Play
                    <p>• Apple App Store</p></p>

                <h4>1 - Data collection and disclosure</h4>

                <p>What information do our products collect, and why?</p>

                <p>• Game data (e.g. coins amount, unlocked characters, saves) is stored locally and remotely so as
                to be able to restore it, assist users, and elaborate user game performance/usage analytics.
                This information is anonymous, and only related to the before mentioned ID. It is not disclosed
                publicly or to third parties in an individual way, but can be published by us in an aggregated
                way as stats (e.g. “most players reach the 3rd level”) or in an anonymous/neutral way (e.g.
                    “best score recorded is 90.278 points”).</p>

                <p>Any other kind of personal information is NOT directly collected by the mentioned products.
                    That includes:</p>

                <p>• Email address or other online contact information
								<p>• Telephone number
									<p>• First/Last names
										<p>• Precise locations/addresses
											<p>• Photograph, video or recordings of the users
												<p>• Files located and managed by the user’s device not related to the
                                    products
													<p>• Etc., check COPPA’s definition of personal information

														<p>Third party services may collect/disclose other types of
                                            personal data, as their respective privacy policy explain.
                                            Section 2, “Third Parties” contains a breakdown of this
                                                services, and a link to their respective privacy policy.</p></p></p></p></p></p></p></p>

                <h4>2 - Third parties</h4>

                <p>The mentioned products include and make use of third party
                services, which may themselves collect user information.
                Below is the list of services used, their purpose and
															location of the privacy policy of each one.</p>

                <p><i>1- Distribution</i></p>

                <p>• Google Play Games:<a target="_blank" href="https://www.google.com/policies/privacy/" rel="noreferrer"> https://www.google.com/policies/privacy/</a>
                    <p>• Apple App Store: <a target="_blank" href="https://www.apple.com/legal/privacy/" rel="noreferrer"> https://www.apple.com/legal/privacy/</a></p></p>

                <p><i>2- Billing</i></p>

                <p>• Google Play Games:<a target="_blank" href="https://www.google.com/policies/privacy/" rel="noreferrer"> https://www.google.com/policies/privacy/</a>
                    <p>• Apple App Store:<a target="_blank" href="https://www.apple.com/legal/privacy/" rel="noreferrer"> https://www.apple.com/legal/privacy/</a></p></p>

                <p><i>3- Advertising</i></p>

                <p>• Unity Ads:<a target="_blank" href="https://unity3d.com/legal/privacy-policy" rel="noreferrer"> https://unity3d.com/legal/privacy-policy</a>
                    <p>• IronSource:<a target="_blank" href="https://developers.ironsrc.com/ironsource-mobile/unity/ironsource-mobile-privacy-policy/" rel="noreferrer"> https://developers.ironsrc.com/ironsource-mobile/unity/ironsource-mobile-privacy-policy/</a>
                        <p>• Facebook:<a target="_blank" href="https://developers.facebook.com/docs/audience-network/policy" rel="noreferrer"> https://developers.facebook.com/docs/audience-network/policy</a>
                            <p>• Admob:<a target="_blank" href="https://www.google.com/policies/privacy/" rel="noreferrer"> https://www.google.com/policies/privacy/</a>
                                <p>• Applovin:<a target="_blank" href="https://www.applovin.com/privacy/" rel="noreferrer"> https://www.applovin.com/privacy/</a></p></p></p></p></p>

                <p><i>4- Analytics</i></p>

                <p>• Unity Analytics:<a target="_blank" href="https://unity3d.com/legal/privacy-policy" rel="noreferrer"> https://unity3d.com/legal/privacy-policy</a>
                    <p>• Firebase:<a target="_blank" href="https://firebase.google.com/support/privacy" rel="noreferrer"> https://firebase.google.com/support/privacy</a></p></p>

                <h4>3 - Data management</h4>

                <p><i>A-Accessing and deleting your information</i></p>

                <p>Contact us at info@euphoriagames.ru</p>

                <p><i>B-Security</i></p>

                <p>While we cannot guarantee that loss, misuse or alteration to your data will not occur, we make good faith efforts to prevent such occurrences. We do not warrant or represent that your account or any of your information or end user information will be protected against, loss, misuse, or alteration by third parties.</p>
                <p>No passwords or other sensitive information is stored by our products, other information stored locally is low-level encrypted and checked upon load, but not in runtime. Information exchanges with online storage services is secured by the standard HTTPS security protocols.</p>

                <p><i>C- Usage information</i></p>

                <p>Usage and gameplay information, we collect through the analytics services is used to understand and improve the experience provided by our products and their technical and commercial performance, and to obtain statistical evidence of user behavior for these and future Euphoria Games products.</p>

                <h4>4 - Final Observations</h4>

                <p>Euphoria Games products are intended for general audiences. If a parent or guardian becomes aware that his or her child has provided us with information without their consent, he or she should contact us at info@euphoriagames.ru</p>
                <p>This privacy policy can be updated and amended, check the date at the beginning of the document.</p>
                <p>Our user’s privacy, trust and experience while using our products are critical. If you think this policy can be improved or corrected, or you simply want to ask us anything, we’ll always be at reach at info@euphoriagames.ru. Never hesitate to write us!</p>
            </Col >
        </Row >
    </Container >
)

export default PrivacyPolicy