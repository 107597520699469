import { useLocation } from 'react-router-dom';
import { useTimeout } from '../../utils/useTimeout';

export const ScrollToTop = () => {
    const { pathname } = useLocation()
    const behavior: any = "instant"
    useTimeout(() => { // use delay for iphone
        window.scrollTo({ top: 0, left: 0, behavior })
    }, 50, [pathname])
    return null
}
