import cn from 'classnames';
import styles from './ArrowDownLink.module.css'

type Props = {
    href: string,
    className?: string
}

export const ArrowDownLink = ({ href, className }: Props) => {
    return (
        <a href={href} className={cn(styles.link, className)}>
            <div className={styles.container}>
                <span></span>
                <span></span>
            </div>
        </a>
    )
}
