import { IconLink } from './IconLink';
import { Service } from '../../constants/services';

type ServiceIconLinkProps = {
    service: Service,
    href: string
}

export const ServiceIconLink = ({ service, href }: ServiceIconLinkProps) => {
    return <IconLink type={service.name} href={href} icon={service.icon} className="ms-3" />;
};
