import { initializeApp } from 'firebase/app';
import { getAnalytics } from "firebase/analytics";

var firebaseConfig = {
    apiKey: "AIzaSyCHiQpT1ndSdFdYeVqQ_MYiWjk7rt0XuBw",
    authDomain: "euphoria-games-website.firebaseapp.com",
    projectId: "euphoria-games-website",
    storageBucket: "euphoria-games-website.appspot.com",
    messagingSenderId: "885522850862",
    appId: "1:885522850862:web:2b59a477c865d694f1ebbf",
    measurementId: "G-T05FMDPS3Z"
}
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

export { analytics }
