import cn from 'classnames';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import { StoreLinks } from './common/StoreLinks';
import { Project, projects } from '../constants/projects';
import { ButtonLink } from './common/ButtonLink';
import { strings } from '../constants/strings';
import { GameStat } from './GameStat';
import { Caption } from './common/Caption';
import { RegistrationLinks } from './common/RegistrationLinks';

export const ProjectList = () => {
    return (
        <>
            {projects().map((project, index) => {
                return (
                    <ProjectRow
                        key={project.title}
                        project={project}
                        reversed={index % 2 === 1}
                    />
                );
            })}
        </>
    );
};

type ProjectRowProps = {
    reversed: boolean,
    project: Project
};

export const ProjectRow = ({ reversed, project }: ProjectRowProps) => {
    return (
        <AnimationOnScroll animateIn={reversed ? "animate__fadeInLeft" : "animate__fadeInRight"} animateOnce>
            <div className="row align-items-center mt-5">
                <div className={cn("col-md-6 p-3", reversed ? "order-md-1" : "order-md-0")}>
                    <a href={project.path || project.links.registration?.googlePlay}>
                        <img className="w-100 img-hover-zoom" src={project.cover} alt={project.title}></img>
                    </a>
                </div>
                <div className={cn("col-md-6 p-3", reversed ? "order-md-0" : "order-md-1")}>
                    <h1>{project.title}</h1>
                    <p className="fs-5 ff-branded letter-spacing-large">
                        {project.rating && project.installs && <GameStat project={project} />}
                    </p>
                    <p>{project.description}</p>
                    <div className="d-flex flex-column align-items-center align-items-md-start">
                        {!project.links.stores && !project.links.registration && !project.path && (
                            <Caption>{strings.soonHeader}</Caption>
                        )}
                        {project.links.stores && (
                            <StoreLinks links={project.links.stores} />
                        )}
                        {project.links.registration && (
                            <RegistrationLinks links={project.links.registration} />
                        )}
                        {project.path && (
                            <div className="mt-3">
                                <ButtonLink to={project.path}>{strings.exploreGameLink}</ButtonLink>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </AnimationOnScroll>
    );
};
