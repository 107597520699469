import { BrowserRouter, Route, Routes } from 'react-router-dom'
import useLocalStorage from 'react-use-localstorage'

import { ScrollToTop } from './components/common/ScrollToTop';
import { ProjectPage } from './pages/project';
import { HomePage } from './pages/home';
import { PrivacyPage } from './pages/privacy';
import { LanguageContext } from './components/common/LanguageContext';
import { strings } from './constants/strings';
import { CurrentScreenObserver } from './components/common/CurrentScreenObserver';
import { MimicryPrivacyPage } from './pages/mimicry/privacy';
import { MimicryTermsPage } from './pages/mimicry/terms';
import { MimicryOfferPage } from './pages/mimicry/offer';
import { PaymentSuccessPage } from './pages/payment/success';
import { PaymentErrorPage } from './pages/payment/error';
import { MimicryAccountPage, MistAccountPage } from './pages/mimicry/account';
import { SupportPage } from './pages/help';

function App() {
    const [language, setLanguage] = useLocalStorage("lang", strings.getLanguage());
    strings.setLanguage(language);

    return (
        <LanguageContext.Provider value={{ language, setLanguage }}>
            <BrowserRouter>
                <ScrollToTop />
                <CurrentScreenObserver />
                <Routes>
                    <Route path="/" element={<HomePage/>} />
                    <Route path="/privacy" element={<PrivacyPage />} />
                    <Route path="/support" element={<SupportPage />} />
                    <Route path="/mimicry/privacy" element={<MimicryPrivacyPage/>} />
                    <Route path="/mimicry/terms" element={<MimicryTermsPage/>} />
                    <Route path="/mimicry/offer" element={<MimicryOfferPage/>} />
                    <Route path="/mimicry/account" element={<MimicryAccountPage/>} />
                    <Route path="/mist/account" element={<MistAccountPage/>} />
                    <Route path="/payment-success" element={<PaymentSuccessPage/>} />
                    <Route path="/payment-error" element={<PaymentErrorPage/>} />
                    <Route path="/:project" element={<ProjectPage/>} />
                </Routes>
            </BrowserRouter>
        </LanguageContext.Provider>
    )
}

export default App;
