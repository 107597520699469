import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination, Navigation } from 'swiper'
import { useMediaQuery } from '@react-hooks-hub/use-media-query'

import 'swiper/scss'
import 'swiper/scss/navigation'
import 'swiper/scss/pagination'
import '../swiper.scss'


type Props = {
    screenshots: string[]
}

export const ProjectScreenshots = ({ screenshots }: Props) => {
    const { device } = useMediaQuery()
    const slidesPerView = screenshots[0].includes("mist") && device !== "mobile" ? 2 : 1
    return (
        <Swiper
            modules={[Autoplay, Pagination, Navigation]}
            pagination={{ clickable: true }}
            autoplay={{ delay: 2000, disableOnInteraction: false }}
            navigation
            slidesPerView={slidesPerView}
            spaceBetween={4}
            className="swiper-branded"
        >
            {screenshots.map((screenshot: any, index: number) => {
                return (
                    <SwiperSlide key={index}>
                        <img className="w-100" src={screenshot} alt={screenshot}></img>
                    </SwiperSlide>
                )
            })}
        </Swiper>
    )
}
