import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Anchor } from '../components/common/Anchor';
import { SectionHeader } from '../components/common/SectionHeader';
import { TeamMember } from '../components/TeamMember';
import { strings } from '../constants/strings';
import { members } from '../constants/team';

export const AboutSection = () => {
    return (
        <section>
            <Anchor id="about" />
            <div className="container my-5">
                <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce>
                    <SectionHeader>{strings.aboutHeader}</SectionHeader>
                    <div className="row">
                        {members().map(member => {
                            return (
                                <div key={member.name} className="col-6 col-lg-3 p-3">
                                    <TeamMember member={member} />
                                </div>
                            );
                        })}
                    </div>
                </AnimationOnScroll>
                <div className="row mt-3">
                    <div className="col-md-6">
                        <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce>
                            <SectionHeader>{strings.storyHeader}</SectionHeader>
                            <p>{strings.storyText}</p>
                        </AnimationOnScroll>
                    </div>
                    <div className="col-md-6">
                        <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce>
                            <SectionHeader>{strings.dreamHeader}</SectionHeader>
                            <p>{strings.dreamText}</p>
                        </AnimationOnScroll>
                    </div>
                </div>
            </div>
        </section>
    )
}
