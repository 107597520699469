import React from 'react'
import { Header } from './Header'
import { Footer } from './Footer'
import { Content } from './Content'

type Props = {
    className?: string,
    logoHidden?: boolean,
    children: React.ReactNode
}

export const Layout = (props: Props) => {
    return (
        <>
            <Header logoHidden={props.logoHidden} />
            <Content>{props.children}</Content>
            <Footer />
        </>
    )
}

