import cn from 'classnames'

import { IconLink } from './IconLink';
import { socials } from '../../constants/services';

export const SocialLinks = () => {
    return (
        <div className="d-flex align-items-center justify-content-center">
            {socials.map((social, index) => {
                return (
                    <IconLink key={social.name} type={social.name} href={social.link} icon={social.icon} className={cn(index !== 0 && "ms-3")} />
                );
            })}
        </div>
    );
};
