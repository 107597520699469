import React from "react";

type Props = {
    children: React.ReactNode
}

export const Content = (props: Props) => {
    return (
        <main className="d-flex flex-column position-relative flex-grow-1 overflow-hidden">
            <div className="header-gradient"></div>
            {props.children}
            <div className="footer-gradient"></div>
        </main>
    )
}