import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cn from 'classnames';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import { features } from "../constants/publishing";
import { Caption } from './common/Caption';

import styles from "./PublishingFeatures.module.css";

export const PublishingFeatures = () => {
    return (
        <div className="row py-3 g-3">
            {features().map((feature, index) => {
                return (
                    <div key={index} className="col-lg-3 col-md-6">
                        <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce>
                            <div className="text-center my-3">
                                <FontAwesomeIcon className={styles.icon} icon={feature.icon} />
                            </div>
                            <Caption className={cn("text-center my-3", styles.title)}>{feature.title}</Caption>
                            <div className="my-3">{feature.description}</div>
                        </AnimationOnScroll>
                    </div>
                );
            })}
        </div>
    );
};
