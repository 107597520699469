import { Layout } from '../../components/layout/Layout'

export const MimicryTermsPage = () => {
    return (
        <Layout>
            <div className="container my-5">
                <Terms />
            </div>
        </Layout>
    )
}

const Terms = () => (
    <div style={{ whiteSpace: "pre-wrap" }}>
        {`
TERMS OF SERVICE (Updated December 21, 2021)


1. INTRODUCTION
 
1.1 Welcome to Euphoria Games. These Terms of Service set out the terms and conditions by which Euphoria Games and its affiliates and subsidiaries (individually and collectively, "Euphoria Games", "we", "us" or "our") offer:
(a) our PC and mobile games (“Games”);
(d) any other apps, websites, games or services that link to this Terms of Service, regardless of how you access or use them, including through mobile devices,
(collectively, the “Services”).
 
1.2 The Services also include any services provided by or through the Euphoria Games platform client software, any services made available through our Games or the Site, and all information, linked pages, features, data, text, images, photographs,  graphics, music, sounds, video, messages, tags, content, programming, software, application services (including without limitation any mobile application services) or other materials made available by or through us ("Content"). Any updates, upgrades and new features added to or augmenting the Services are also subject to these Terms of Service.
 
1.3 Please read these Terms of Service carefully before using the Services or opening a Euphoria Games account ("Account") so that you are aware of your legal rights and obligations with respect to Euphoria Games.
 
1.4 BY USING THE SERVICES OR OPENING AN ACCOUNT, YOU SIGNIFY YOUR IRREVOCABLE ACCEPTANCE OF THESE TERMS OF SERVICE. IF YOU DO NOT AGREE TO THESE TERMS, PLEASE DO NOT USE OUR SERVICES.  IF YOU ARE YOUNGER THAN 18 YEARS OLD OR THE RELEVANT "AGE OF MAJORITY" WHERE YOU LIVE, YOU MUST GET PERMISSION FROM A PARENT OR LEGAL GUARDIAN TO OPEN AN ACCOUNT AND THAT PARENT OR LEGAL GUARDIAN MUST AGREE TO THESE TERMS OF SERVICE. IF YOU DO NOT KNOW WHETHER YOU HAVE REACHED THE "AGE OF MAJORITY" WHERE YOU LIVE, OR DO NOT UNDERSTAND THIS SECTION, PLEASE DO NOT CREATE AN ACCOUNT UNTIL YOU HAVE ASKED YOUR PARENT OR LEGAL GUARDIAN FOR HELP. IF YOU ARE THE PARENT OR LEGAL GUARDIAN OF A MINOR WHO IS CREATING AN ACCOUNT, YOU MUST ACCEPT THESE TERMS OF SERVICE ON THE MINOR'S BEHALF AND YOU WILL BE RESPONSIBLE FOR ALL USE OF THE ACCOUNT OR SERVICES, INCLUDING PURCHASES MADE BY THE MINOR, WHETHER THE MINOR'S ACCOUNT IS NOW OPEN OR CREATED LATER AND WHETHER OR NOT THE MINOR IS SUPERVISED BY YOU DURING SUCH PURCHASE.
 
1.5 Euphoria Games has the right to revise these Terms of Service at any time without providing notice to its users. It is your responsibility to check this page regularly to make note of any changes.  Your continued use of the Services shall be deemed irrevocable acceptance of those revisions.
 
1.6 Euphoria Games reserves the right to change, modify, suspend or discontinue any portion of the Services at any time. Euphoria Games may release certain Services or their features in a beta version, which may not work correctly or in the same way the final version may work, and we shall not be held liable in such instances. Euphoria Games may also impose limits on certain features or restrict your access to parts of, or the entire, Services in its sole discretion and without notice or liability. 
 
1.7 Euphoria Games reserves the right to refuse to provide you access to the Services or to allow you to open an Account for any reason.
 
2. PRIVACY
 
2.1 Your privacy is very important to us at Euphoria Games. To better protect your rights, we have provided the Euphoria Games Privacy Policy to explain our privacy practices in detail.  Please review the Privacy Policy to understand how Euphoria Games collects and uses the information associated with your Account and/or your use of the Services. By using the Services or agreeing to these Terms of Service, you consent to Euphoria Games's collection, use, disclosure and/or processing of your Content and personal data as described in the Privacy Policy.
 
3. LIMITED LICENSE
 
3.1 Euphoria Games grants you a non-exclusive, non-transferable limited right and license to install the Software and access and use the Services, subject to the terms and conditions of these Terms of Service and for personal use only. This license does not allow you to make any commercial use or any derivative use of the Services (including without limitation any of its individual elements, including but not limited to the Games, Software or Content).  All rights not specifically granted by Euphoria Games under these Terms of Service are hereby reserved by Euphoria Games.  Any third-party scripts or code, linked to or referenced from the Services, are licensed to you by the third parties that own such scripts or code, not by Euphoria Games.
 
3.2 You acknowledge and agree that all title, ownership rights and intellectual property rights connected with the Services (including but not limited to any derivative works, titles, computer code, objects, themes, characters, character names, stories, dialogues, catch phrases, locations, concepts, artwork, graphics, animation, sounds, musical compositions, audio-visual effects, text, screen displays, methods of operation, moral rights, “applets” incorporated in the Services, and any related documentation) are the property of Euphoria Games and where applicable, third party proprietors identified in the Services. 
 
3.3 By using or accessing the Services you agree to comply with the copyright, trademark, service mark, and all other applicable laws that protect the Services and its Content. You agree not to copy, distribute, republish, transmit, publicly display, publicly perform, modify, adapt, rent, sell, or create derivative works of any portion of the Services or its Content. You also may not, without our prior written consent, mirror or frame any part or whole of the Services on any other server or as part of any other website. In addition, you agree that you will not use any robot, spider or any other automatic device or manual process to monitor or copy our Content, without our prior written consent (such consent is deemed given for standard search engine technology employed by Internet search websites to direct Internet users to this website).
 
3.4 You are welcome to link to the Site from your website, provided that your website does not imply any endorsement by or association with Euphoria Games. You acknowledge that Euphoria Games may, in its sole discretion and at any time, discontinue providing any part of the Services without notice.
 
4. TERMS OF USE
 
4.1 The license for use of the Services is effective until terminated as set forth in these Terms of Service or if you fail to comply with any term or condition of these Terms of Service. In such event, no notice shall be required by Euphoria Games to effect such termination.
 
4.2 You agree not to:
(a) upload, post, email, transmit or otherwise make available any Content that is unlawful, harmful, threatening, abusive, harassing, alarming, distressing, tortuous, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable; 
(b) use the Services to harm minors in any way; 
(c) use the Services to impersonate any person or entity, or otherwise misrepresent your affiliation with a person or entity; 
(d) forge headers or otherwise manipulate identifiers in order to disguise the origin of any Content transmitted through the Services; 
(e) remove any proprietary notices from the Services; 
(f) cause, permit or authorize the modification, creation of derivative works, or translation of the Services without the express permission of Euphoria Games; 
(g) use the Services for any commercial purpose or the benefit of any third party or any manner not permitted by the licenses granted herein; 
(h) use the Services for fraudulent purposes; 
(i) attempt to decompile, reverse engineer, disassemble or hack the Services (or any portion thereof), or to defeat or overcome any encryption technology or security measures implemented by Euphoria Games with respect to the Services and/or data transmitted, processed or stored by Euphoria Games; 
(j) harvest or collect any information about or regarding other Account holders, including without limitation any personal data or information; 
(k) upload, post, email, transmit or otherwise make available any Content that you do not have a right to make available under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements); 
(l) upload, post, email, transmit or otherwise make available any Content that infringes any patent, trademark, trade secret, copyright or other proprietary rights of any party; 
(m) upload, post, email, transmit or otherwise make available any unsolicited or unauthorized advertising, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other unauthorized form of solicitation; 
(n) upload, post, email, transmit or otherwise make available any material that contains software viruses, worms, Trojan-horses or any other computer code, routines, files or programs designed to directly or indirectly interfere with, manipulate, interrupt, destroy or limit the functionality or integrity of any computer software or hardware or data or telecommunications equipment; 
(o) disrupt the normal flow of dialogue, cause a screen to "scroll" faster than other users of the Services are able to type, or otherwise act in a manner that negatively affects other users' ability to engage in real time exchanges; 
(p) interfere with, manipulate or disrupt the Services or servers or networks connected to the Services or any other user's use and enjoyment of the Services, or disobey any requirements, procedures, policies or regulations of networks connected to the Services; 
(q) take any action or engage in any conduct that could directly or indirectly damage, disable, overburden, or impair the Services or the servers or networks connected to the Services;
(r) use the Services to intentionally or unintentionally violate any applicable local, state, national or international law, rule, code, directive, guideline, policy or regulation including without limitation laws and requirements (whether or not having the force of law) relating to anti-money laundering or counter-terrorism; 
(s) use the Services to provide material support or resources (or to conceal or disguise the nature, location, source, or ownership of material support or resources) to any organization(s) designated by the United States government as a foreign terrorist organization pursuant to section 219 of the Immigration and Nationality Act; 
(t) use the Services to violate the privacy of others or to "stalk" or otherwise harass another; and/or 
(u) use the Services to collect or store personal data about other users in connection with the prohibited conduct and activities set forth above. 
 
4.3 You understand that all Content, whether publicly posted or privately transmitted, is the sole responsibility of the person from whom such Content originated. This means that you, and not Euphoria Games, are entirely responsible for all Content that you upload, post, email, transmit or otherwise make available through the Services. Euphoria Games does not control the Content posted through the Services and, as such, does not guarantee the accuracy, integrity or quality of such Content. You understand that by using the Services, you may be exposed to Content that you may consider to be offensive, indecent or objectionable. Under no circumstances will Euphoria Games be liable in any way for any Content, including, but not limited to, any errors or omissions in any Content, or any loss or damage of any kind incurred as a result of the use of, or reliance on, any Content posted, emailed, transmitted or otherwise made available through the Services.
 
4.4 You acknowledge that Euphoria Games may or may not pre-screen Content, but that Euphoria Games and its designees shall have the right (but not the obligation) in their sole discretion to pre-screen, refuse, delete or remove any Account or Content that is available through the Services for any reason. Without limiting the foregoing, Euphoria Games and its designees shall have the right to remove any Account or Content that violates these Terms of Service, if we receive a complaint from another user, if we receive a notice of intellectual property infringement or other legal instruction for removal (including but not limited to any notice, instruction or order by any regulatory or governmental body to remove, suspend or delete any Account or Content), or if such Account or Content is otherwise objectionable. We may also block delivery of a communication (including without limitation status updates, postings, messages and/or chats) to or from the Services as part of our effort to protect the Services or our users, or otherwise enforce the provisions of these Terms and Conditions. You agree that you must evaluate, and bear all risks associated with, the use of any Content, including, without limitation, any reliance on the accuracy, completeness, or usefulness of such Content. In this regard, you acknowledge that you may not rely on any Content created by Euphoria Games or submitted to Euphoria Games, including without limitation information in Euphoria Games forums, instant messaging channels and in all other parts of the Services.
 
4.5 In the event your Account is deleted or deactivated in accordance with the preceding Clause 4.4, all Virtual Currency and Virtual Items (as defined below) in your Account shall be forfeited to the fullest extent permitted by law

4.6 You acknowledge, consent and agree that Euphoria Games may access, preserve and disclose your Account information and Content if required to do so by law or pursuant to an order of a court or by any governmental or regulatory authority having jurisdiction over Euphoria Games or in a good faith belief that such access preservation or disclosure is reasonably necessary to: (a) comply with legal process; (b) enforce these Terms of Service; (c) respond to claims that any Content violates the rights of third parties; (d) respond to your requests for customer service; or (e) protect the rights, property or personal safety of Euphoria Games, its users and/or the public.
 
5. SOFTWARE UPDATES AND PATCHES
 
5.1 We may provide updates, patches and other modifications to the Services that must be installed for you to continue to play our Games properly or at all. We may update, patch or modify the Software remotely and access the Software residing on your machine or device for such purpose, and you hereby grant to Euphoria Games the right to deploy and apply such patches, updates and modifications. All provisions of these Terms of Service that refer to “Software” shall also include all such patches, updates and modifications.
 
6. ACCOUNTS AND SECURITY
 
6.1 Some functions of our Services require registration for an Account by selecting a unique user identification ("User ID") and password, and by providing certain personal information. If you select a User ID that Euphoria Games, in its sole discretion, finds offensive or inappropriate, Euphoria Games has the right to suspend or terminate your Account. You may be able to use your Account to gain access to other products, websites or services to which we have enabled access or with which we have tied up or collaborated. Euphoria Games has not reviewed, and assumes no responsibility for any third party content, functionality, security, services, privacy policies, or other practices of those products, websites or services. If you do so, the terms of service for those products, websites or services, including their respective privacy policies, if different from these Terms of Service and/or our Privacy Policy, may also apply to your use of those products, websites or services.
 
6.2 You agree to (a) keep your password confidential and use only your User ID and password when logging in, (b) ensure that you log out from your account at the end of each session, (c) immediately notify Euphoria Games of any unauthorized use of your User ID and/or password, and (d) ensure that your Account information is accurate and up-to-date. You are fully responsible for all activities that occur under your User ID and Account even if such activities or uses were not committed by you. Euphoria Games will not be liable for any loss or damage arising from unauthorized use of your password or your failure to comply with this Section.
 
6.3 You agree that Euphoria Games may for any reason, in its sole discretion and without notice or liability to you or any third party, immediately terminate your Account and your User ID, and remove or discard from the Services any Content associated with your Account and User ID. Grounds for such termination may include, but are not limited to, (a) extended periods of inactivity, (b) violation of the letter or spirit of these Terms of Service, (c) violation of any applicable law, (d) fraudulent, harassing, defamatory, threatening or abusive behavior or (e) behavior that is harmful to other users, third parties, or the business interests of Euphoria Games. Use of an Account for illegal, fraudulent, harassing, defamatory, threatening or abusive purposes may be referred to law enforcement authorities without notice to you. If you file a claim (under any cause of action whatsoever) either against Euphoria Games, or which in any way involves Euphoria Games, then Euphoria Games may terminate your Account.
 
6.4 If your Account is or remains inactive (which means you have not logged into your Account) for more than 3 months, Euphoria Games has the right to delete or deactivate your Account and all Virtual Currency and Virtual Items (as defined below) in your Account shall be forfeited.
 
6.5 You may only use the Services and/or open an Account if your applicable jurisdiction allows you to accept these Terms of Service.
 
7. CHARGES AND PAYMENTS
 
7.1 If there is a charge associated with the purchase of Euphoria Games Silver, Gold, or other in-game virtual currency (“Virtual Currency ”), virtual in-game items (“Virtual Items”) or other Services, you agree to pay that charge in order to receive such Virtual Currency, Virtual Items or to receive access to and/or the benefits of such Services. The price stated for the Virtual Currency, Virtual Items and/or Services excludes all applicable taxes and currency exchange settlements, unless stated otherwise. You are solely responsible for paying such taxes or other charges. We may suspend or cancel your access to the Services if we do not receive full payment from you within the stipulated due date for payment. Suspension or cancellation of the Services for non-payment could result in a loss of access to and use of your Account and its Content.
 
7.2 To pay the charges for any Virtual Currency, Virtual Items or other Service, you will be asked to select a payment method before you complete your purchase or at the time you sign up for that Service. You can access and change your billing account information and payment method at any time. You agree to allow Euphoria Games to use any updated account information regarding your payment method provided by your issuing bank or the applicable payment network. You agree to keep your billing account information current at all times. Changes that you make to your billing account will not affect charges we submit to your billing account before we are able to reasonably act on your changes.
 
7.3 By providing Euphoria Games with a payment method or otherwise purchasing any Virtual Currency, Virtual Items or other Services, you:
(a) represent that you are authorized to use the payment method that you provided and that any payment information you provide is true and accurate; 
(b) authorize Euphoria Games to charge you for the Services using your preferred payment method; and
(c) authorize Euphoria Games to charge you for any paid feature of the Services that you choose to sign up for or use while these Terms of Service are in force.
 
7.4 Depending on the nature of the purchase, we may bill you (a) in advance; (b) at the time of purchase or (c) on a recurring basis for a subscription-based Service.
 
7.5 We may change the price of Virtual Currency, Virtual Items and/or any Service at any time. If there is a fixed term and price for your Service offer, that price will remain in force for the duration of that term.
 
7.6 Once purchased, any purchased Virtual Currency or Virtual Items will be valid indefinitely until your Account is terminated for any reason or if we are no longer able to legally service your Account (for example, if you withdraw your consent for us to use your personal data). If your Account is or remains inactive (which means you have not logged into your Account) for more than 3 months, Euphoria Games has the right to delete or deactivate your Account and all Virtual Currency and Virtual Items in your Account, shall be forfeited.
 
7.7 Virtual Currency and Virtual Items have no monetary value and have no value outside of the Services. Virtual Currency and Virtual Items cannot be sold, traded, transferred, or exchanged for cash. Virtual Currency may be only redeemed for Virtual Items or other entitlements available via our Games and Services.

7.8 Unless otherwise provided by applicable law or by the express terms of a Service offer, all purchases are final and non-refundable. Once you redeem any Virtual Currency for a Virtual Item or other entitlement, that Virtual Item or other entitlement is final and not returnable, exchangeable, or refundable. You may cancel any Services at any time, but all purchased Silver and Gold are strictly non-refundable.
 
8. MEMBERSHIP SUBSCRIPTIONS
 
8.1 We may offer membership subscription plans in our Games (“Subscriptions”).  Subscriptions may be weekly, monthly or such other recurring period as determined by us and notified in our Games (“Subscription Period”).
 
8.2 Subscriptions may be purchased by paying the fee specified in our Games (“Subscription Fee”) and are subject to the terms and conditions in Clause 7 above and the additional terms and conditions published within our Games.
 
8.3 Subscriptions will be automatically renewed for further Subscription Periods, and you will be charged the Subscription Fee, at the end of each Subscription Period until canceled by you.  As we will not notify you prior to such renewal, you are advised to monitor your Subscriptions.
 
8.4 You may cancel your Subscription at any time through Google Play or the App Store (as the case may  be).  Upon cancellation, your Subscription shall not be renewed and shall expire on the last day of the Subscription Period.  In the event your Subscription Fee or any part of it is refunded, we reserve the right to deduct any Virtual Currency or Virtual Items previously granted by us in the Subscription Period prior to your cancellation taking effect.
 
8.5 We may allow you to subscribe for more than one Subscription at a time.  Subscriptions may have limited availability.  Once Subscriptions are fully subscribed, they may no longer be made available for purchase.  We reserve the right to make changes to our Subscriptions at any time.  
 
9. DISCLAIMER
 
9.1 THE SERVICES ARE PROVIDED "AS IS" AND WITHOUT ANY WARRANTIES, CLAIMS OR REPRESENTATIONS MADE BY EUPHORIA GAMES OF ANY KIND EITHER EXPRESS, IMPLIED OR STATUTORY WITH RESPECT TO THE SERVICES, INCLUDING WITHOUT LIMITATION WARRANTIES OF QUALITY, PERFORMANCE, NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE, NOR ARE THERE ANY WARRANTIES CREATED BY COURSE OF DEALING, COURSE OF PERFORMANCE OR TRADE USAGE. WITHOUT LIMITING THE FOREGOING, EUPHORIA GAMES DOES NOT WARRANT THAT THE SERVICES OR THE FUNCTIONS CONTAINED THEREIN WILL BE AVAILABLE, ACCESSIBLE, UNINTERRUPTED, TIMELY, SECURE, ACCURATE, COMPLETE OR ERROR-FREE, THAT DEFECTS, IF ANY, WILL BE CORRECTED, OR THAT THE SERVICES AND/OR THE SERVER THAT MAKES SAME AVAILABLE ARE FREE OF VIRUSES, CLOCKS, TIMERS, COUNTERS, WORMS, SOFTWARE LOCKS, DROP DEAD DEVICES, TROJAN-HORSES, ROUTINGS, TRAP DOORS, TIME BOMBS OR ANY OTHER HARMFUL CODES, INSTRUCTIONS, PROGRAMS OR COMPONENTS.  YOU ACKNOWLEDGE THAT THE ENTIRE RISK ARISING OUT OF THE USE OR PERFORMANCE OF THE SERVICES REMAINS WITH YOU TO THE MAXIMUM EXTENT PERMITTED BY LAW.
 
9.2 Some jurisdictions do not allow the disclaimer of implied warranties, so some or all of the foregoing disclaimers may not apply to you.
 
10. EXCLUSIONS AND LIMITATION OF LIABILITY
 
10.1 IN NO EVENT SHALL EUPHORIA GAMES BE LIABLE WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING WITHOUT LIMITATION NEGLIGENCE (WHETHER ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY OR STRICT LIABILITY OR OTHER THEORY), OR OTHER CAUSE OF ACTION AT LAW, IN EQUITY, BY STATUTE OR OTHERWISE, FOR LOSS OF USE, PROFITS, REVENUES, GOODWILL OR ANTICIPATED SAVINGS OR FOR ANY INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES (INCLUDING WITHOUT LIMITATION ANY LOSS OF DATA, SERVICE INTERRUPTION, COMPUTER, MOBILE PHONE OR MOBILE DEVICE FAILURE) ARISING OUT OF OR IN CONNECTION WITH THE USE OR INABILITY TO USE THE SERVICES, INCLUDING, WITHOUT LIMITATION, ANY DAMAGES RESULTING THEREFROM, EVEN IF EUPHORIA GAMES HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
 
10.2 YOUR ONLY RIGHT WITH RESPECT TO ANY PROBLEMS OR DISSATISFACTION WITH THE SERVICES IS TO TERMINATE YOUR ACCOUNT AND DISCONTINUE ANY USE OF THE SERVICES.
 
10.3 IF, NOTWITHSTANDING THE PREVIOUS SECTIONS, EUPHORIA GAMES IS FOUND BY A COURT OF COMPETENT JURISDICTION TO BE LIABLE (INCLUDING FOR GROSS NEGLIGENCE), ITS LIABILITY TO YOU OR TO ANY THIRD PARTY IS LIMITED TO USD 100 (ONE HUNDRED US DOLLARS).
 
10.4 NOTHING IN THESE TERMS OF SERVICE SHALL LIMIT OR EXCLUDE ANY LIABILITY FOR DEATH OR PERSONAL INJURY CAUSED BY EUPHORIA GAMES’S NEGLIGENCE, FOR FRAUD OR FOR ANY OTHER LIABILITY THAT CANNOT BE LAWFULLY LIMITED AND/OR EXCLUDED.
 
11. LINKS TO THIRD PARTY SITES
 
11.1 Any links provided through the Services will let you leave the Site or Games. These links are provided as a courtesy only, and the sites they link to are not under the control of Euphoria Games in any manner whatsoever and you therefore access them at your own risk. Therefore, Euphoria Games is in no manner responsible for the contents of any such linked site or any link contained within a linked site, including any changes or updates to such sites. Euphoria Games is providing these links merely as a convenience, and the inclusion of any link does not in any way imply or express affiliation, endorsement or sponsorship by Euphoria Games of any linked site and/or any of its content therein.
 
12. YOUR CONTRIBUTIONS TO THE SERVICES
 
12.1 By submitting Content for inclusion on the Services, you represent and warrant that you have all necessary permissions to grant the licenses below to Euphoria Games. You further acknowledge and agree that you are solely responsible for anything you post or otherwise make available on or through the Services, including, without limitation, the accuracy, reliability, nature, rights clearance, compliance with law and legal restrictions associated with any Content contribution. You hereby grant Euphoria Games and its successors an irrevocable, worldwide, non-exclusive, royalty-free, sub-licensable and transferable license to use, copy, distribute, republish, transmit, modify, adapt, create derivative works of, publicly display, and publicly perform such Content contribution on, through or in connection with the Services in any media formats and through any media channels, including without limitation, for promoting and redistributing part of the Services (and its derivative works). You understand that your contribution may be transmitted over various networks and changed to conform and adapt to technical requirements.
 
12.2 Any material, information or idea you post on or through the Services, or otherwise transmit to Euphoria Games by any means (each, a "Submission"), is not considered confidential by Euphoria Games and may be disseminated or used by Euphoria Games or its affiliates without compensation or liability to you for any purpose whatsoever, including, but not limited to, developing, manufacturing and marketing products. By making a Submission to Euphoria Games, you acknowledge and agree that Euphoria Games and/or other third parties may independently develop software, applications, interfaces, products and modifications and enhancements of the same which are identical or similar in function, code or other characteristics to the ideas set out in your Submission. Accordingly, you hereby grant Euphoria Games and its successors an irrevocable, worldwide, non-exclusive, royalty-free, sub-licensable and transferable license to develop the items identified above, and to use, copy, distribute, republish, transmit, modify, adapt, create derivative works of, publicly display, and publicly perform any Submission on, through or in connection with the Services in any media formats and through any media channels, including without limitation, for promoting and redistributing part of the Services (and its derivative works). This license granted by you will continue for as long as Euphoria Games determines to use your Submission. This provision does not apply to personal information that is subject to our privacy policy except to the extent that you make such personal information publicly available on or through the Services.
 
13. THIRD PARTY CONTRIBUTIONS TO THE SERVICES AND EXTERNAL LINKS
 
13.1 Each contributor to the Services of data, text, images, sounds, video, software and other Content is solely responsible for the accuracy, reliability, nature, rights clearance, compliance with law and legal restrictions associated with their Content contribution. As such, Euphoria Games is not responsible to, and shall not, regularly monitor or check for the accuracy, reliability, nature, rights clearance, compliance with law and legal restrictions associated with any contribution of Content. You will not hold Euphoria Games responsible for any user's actions or inactions, including, without limitation, things they post or otherwise make available via the Services.
 
13.2 In addition, the Services may contain links to third party text and video feeds (and podcasts) (collectively, "third party feeds"), products, websites, services and offers, or links to download third party software applications. Additionally, third parties may make available, on their own websites, third party feeds, and software applications. These third party links, third party feeds, products, websites, services and software applications are not owned or controlled by Euphoria Games. Rather, they are operated by, and are the property of, the respective third parties, and may be protected by applicable copyright or other intellectual property laws and treaties. Euphoria Games has not reviewed, and assumes no responsibility for the content, functionality, security, services, privacy policies, or other practices of these third parties. You are encouraged to read the terms and other policies published by such third parties on their websites or otherwise. By using the Services, you agree that Euphoria Games shall not be liable in any manner due to your use of, or inability to use, any third-party feed, website or widget. You further acknowledge and agree that Euphoria Games may disable your use of, or remove, any third party links, third party feeds, or applications on the Services to the extent they violate these Terms of Service.
 
14. VIOLATION OF OUR TERMS OF SERVICE
 
14.1 If you believe a user of our Services is violating these Terms of Service, please contact us at info@euphoriagames.ru
 
15. MAKING CLAIMS OF COPYRIGHT INFRINGEMENT
 
15.1 If you believe that your work (as included on the Services) has been copied, displayed, or distributed in a way that constitutes copyright infringement, please notify our Copyright Agent.  A notification of claimed infringement must be a written communication as set forth below, and must include substantially all of the following: 
(a) a physical or electronic signature of a person authorized to act on behalf of the owner of the copyright interest that is allegedly infringed; 
(b) a description of such copyrighted work(s) and an identification of what material in such work(s) is claimed to be infringed; 
(c) a description of the exact name of the infringing work and the location of the infringing work on the Services; 
(d) information sufficient to permit Euphoria Games to contact you, such as your physical address, telephone number and e-mail address; 
(e) a statement by you that you have a good faith belief that the use of the material identified in the manner complained of is not authorized by the copyright owner, its agent, or the law; and
(f) a statement by you that the information in the notification is accurate and, under penalty of perjury, that you are authorized to act on the copyright owner's behalf.
 
The Euphoria Games Copyright Agent can be reached by email: info@euphoriagames.ru
The Copyright Agent will only respond to any claims involving alleged copyright infringement.
 
16. YOUR REPRESENTATIONS AND WARRANTIES
 
16.1 You represent and warrant that:
 
(a) you possess the legal capacity (and in the case of a minor, valid parent or legal guardian consent), right and ability to enter into these Terms of Service and to comply with its terms;
 
(b) you will use the Services for lawful purposes only and in accordance with these Terms of Service and all applicable laws, rules, codes, directives, guidelines, policies and regulations; and
 
(c) you will only use the Services on a machine or device on which such use is authorized by the machine’s or device’s owner.
 
17. INDEMNITY
 
17.1 You agree to indemnify, defend and hold harmless Euphoria Games, and its shareholders, subsidiaries, affiliates, officers, agents, licensors, co-branders or other partners, and employees (collectively, the "Indemnified Parties"), at your expense, from and against any and all claims, actions, proceedings, and suits and all related liabilities, damages, settlements, penalties, fines, costs and expenses (including, without limitation, reasonable legal fees and other dispute resolution expenses) incurred by any Indemnified Party arising out of or relating to (a) the hosting, operation, management and/or administration of the Services by or on behalf of Euphoria Games, (b) your violation or breach of any term of these Terms of Service or any policy or guidelines referenced herein, (c) your use or misuse of the Services, or (d) your breach of any law or regulation or the rights of a third party, or (e) the removal of your Account or any Content that you upload, post, email, transmit or otherwise make available through the Services in accordance with Clause 4.4 above.
 
18. SEVERABILITY
 
18.1 If any provision of these Terms of Service shall be deemed unlawful, void, or for any reason unenforceable under the law of any jurisdiction, then that provision shall be deemed severable from these terms and conditions and shall not affect the validity and enforceability of any remaining provisions in such jurisdiction nor the validity and enforceability of the provision in question under the law of any other jurisdiction.
 
19. GOVERNING LAW
 
19.1 These Terms of Service shall be governed by and construed in accordance with the laws of Russia without regard to its conflict of law rules. The United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transaction Act, to the extent applicable, are expressly disclaimed. Any dispute, controversy, claim or difference of any kind whatsoever shall arising out of or relating to these Terms of Service against or relating to Euphoria Games or any Indemnified Party under these Terms of Service shall be referred to and finally resolved by arbitration in Russia in accordance with the Arbitration Rules of Russia for the time being in force, which rules are deemed to be incorporated by reference in this Section. There will be one (1) arbitrator and the language of the arbitration shall be in Russian.
 
20. GENERAL PROVISIONS
 
20.1 Euphoria Games reserves all rights not expressly granted herein. 
 
20.2 Euphoria Games may modify these Terms of Service at any time by posting the revised Terms of Service on the Site. Your continued use of the Services after such changes have been posted shall constitute your acceptance of such revised Terms of Service. 
 
20.3 You may not assign, sublicense or transfer any rights granted to you hereunder or subcontract any of your obligations.
 
20.4 Nothing in these Terms of Service shall constitute a partnership, joint venture or principal-agent relationship between you and Euphoria Games, nor does it authorize you to incur any costs or liabilities on Euphoria Games’s behalf.
 
20.5 The failure of Euphoria Games at any time or times to require performance of any provision hereof shall in no manner affect its right at a later time to enforce the same unless the same is waived in writing.
 
20.6 These Terms of Service are solely for your and our benefit and are not for the benefit of any other person or entity, except for Euphoria Games's affiliates and subsidiaries (and each of Euphoria Games's and its affiliates' and subsidiaries' respective successors and assigns).
 
20.7 The terms set forth in these Terms of Service and any agreements and policies included or referred to in these Terms of Service constitute the entire agreement and understanding of the parties with respect to the Services and supersede any previous agreement or understanding between the parties in relation to such subject matter. The parties also hereby exclude all implied terms in fact. In entering into the agreement formed by these Terms of Service, the parties have not relied on any statement, representation, warranty, understanding, undertaking, promise or assurance of any person other than as expressly set out in these Terms of Service. Each party irrevocably and unconditionally waives all claims, rights and remedies which but for this Section it might otherwise have had in relation to any of the foregoing. These Terms of Service may not be contradicted, explained or supplemented by evidence of any prior agreement, any contemporaneous oral agreement or any consistent additional terms.
 
20.8 You agree to comply with all applicable laws, statutes, regulations and codes relating to anti-bribery and corruption and confirm that you have and shall have in place all policies and procedures needed to ensure compliance with such requirements.
 
20.9 If you have any questions or concerns about these Terms of Service or any issues raised in these Terms of Service, please contact us at: info@euphoriagames.ru.
        `}
    </div>
)
