import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';

import { StoreLinks } from './common/StoreLinks';
import { Project } from '../constants/projects';
import { ProjectScreenshots } from './ProjectScreenshots';
import { Caption } from './common/Caption';
import { strings } from '../constants/strings';
import { GameStat } from './GameStat';
import { RegistrationLinks } from './common/RegistrationLinks';
import { Link } from 'react-router-dom';

type ProjectDetailsProps = {
    project: Project
};

export const ProjectDetails = ({ project }: ProjectDetailsProps) => {
    return (
        <>
            <div className="row align-items-center">
                <div className="col-md-6 p-3">
                    <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
                        <img className="w-100 img-hover-zoom" src={project.cover} alt={project.title}></img>
                    </AnimationOnScroll>
                </div>
                <div className="col-md-6 p-3">
                    <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
                        <h1>{project.title}</h1>
                        <p className="fs-5 ff-branded letter-spacing-large">
                            {project.rating && project.installs && <GameStat project={project} />}
                        </p>
                        <div className="my-3">{project.description}</div>
                        <div>
                            <Caption>{strings.featuresHeader}</Caption>
                            <ul>
                                {project.features.map(feature => {
                                    return (
                                        <li key={feature}>{feature}</li>
                                    );
                                })}
                            </ul>
                        </div>
                        {project.hasOffer && <Link to="offer">{strings.publicOffer}</Link>}
                        {project.links.stores && <StoreLinks links={project.links.stores} />}
                        {project.links.registration && <RegistrationLinks links={project.links.registration} />}
                    </AnimationOnScroll>
                </div>
                <div className="col-md-6 p-3">
                    {project.links.youtube && (
                        <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
                            <div className="ratio ratio-16x9">
                                <iframe
                                    title="project-video"
                                    src={project.links.youtube}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    {...{ "enablejsapi": "1" }}
                                />
                            </div>
                        </AnimationOnScroll>
                    )}
                </div>
                <div className="col-md-6 p-3">
                    <AnimationOnScroll animateIn="animate__fadeIn" animateOnce>
                        <ProjectScreenshots screenshots={project.screenshots} />
                    </AnimationOnScroll>
                </div>
            </div>
        </>
    );
};


