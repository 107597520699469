import { faBullhorn, faCode, faLifeRing, faMagic } from "@fortawesome/free-solid-svg-icons";
import { strings } from "./strings";

export const features = () => [
    {
        title: strings.developmentHeader,
        description: strings.developmentText,
        icon: faCode
    },
    {
        title: strings.marketingHeader,
        description: strings.marketingText,
        icon: faMagic
    },
    {
        title: strings.promotionHeader,
        description: strings.promotionText,
        icon: faBullhorn
    },
    {
        title: strings.supportHeader,
        description: strings.supportText,
        icon: faLifeRing
    }
];
