import { strings } from "./strings";

export type Member = {
    name: string,
    role: string,
    image: string
};

export const members: () => Member[] = () => [
    {
        name: strings.anatoly,
        role: strings.roleFounderDirector,
        image: "images/Tol.png"
    },
    {
        name: strings.anton,
        role: strings.roleFounderDeveloper,
        image: "images/Anton.png"
    },
    {
        name: strings.dmitry,
        role: strings.roleCoownerDeveloper,
        image: "images/Dima.png"
    },
    {
        name: strings.ela,
        role: strings.roleCommunityManager,
        image: "images/Ela.png"
    }
];
