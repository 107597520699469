import { useCallback } from 'react'
import { logEvent } from "firebase/analytics"
import { analytics } from '../../utils/firebase'


type Props = {
    type: string,
    href: string,
    image: string,
    className?: string
}

export const ImageLink = ({ type, href, image, className }: Props) => {
    const logClick = useCallback(() => logEvent(analytics, "select_content", { content_type: type, content_id: href }), [type, href])
    return (
        <a href={href} target="_blank" rel="noreferrer" onClick={logClick}>
            <img src={image} alt={type} className={className}></img>
        </a>
    )
}
