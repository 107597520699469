import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { logEvent } from "firebase/analytics"
import { analytics } from '../../utils/firebase';


type Props = {
    type: string,
    href: string,
    icon: IconDefinition,
    className?: string
}

export const IconLink = ({ type, href, icon, className }: Props) => {
    const logClick = useCallback(() => logEvent(analytics, "select_content", { content_type: type, content_id: href }), [type, href])
    return (
        <a href={href} target="_blank" rel="noreferrer" className={className} onClick={logClick}>
            <FontAwesomeIcon icon={icon} />
        </a>
    )
}
