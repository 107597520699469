import React from 'react';
import cn from 'classnames';

type Props = {
    className?: string,
    children: string
}

export const Caption = ({ className, children }: Props) => {
    return (
        <div className={cn("text-uppercase fw-bold letter-spacing-large", className)}>{children}</div>
    );
};
