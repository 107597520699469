import { Layout } from '../../components/layout/Layout'
import { strings } from '../../constants/strings'

export const MimicryAccountPage = () => {
    return (
        <Layout>
            <div className="container my-5">
                <h1>{strings.mimicryAccountManagementTitle}</h1>
                <div style={{ whiteSpace: "pre-wrap" }}>{strings.mimicryAccountManagementText.map(line => <p>{line}</p>)}</div>
            </div>
        </Layout>
    )
}

export const MistAccountPage = () => {
    return (
        <Layout>
            <div className="container my-5">
                <h1>{strings.mistAccountManagementTitle}</h1>
                <div style={{ whiteSpace: "pre-wrap" }}>{strings.mistAccountManagementText.map(line => <p>{line}</p>)}</div>
            </div>
        </Layout>
    )
}
