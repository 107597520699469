import React from "react";
import { Member } from "../constants/team";

type Props = {
    member: Member
};

export const TeamMember = ({ member }: Props) => {
    return (
        <div className="d-flex flex-column align-items-center">
            <div>
                <img className="w-100 img-hover-zoom" src={member.image} alt={member.name} />
            </div>
            <div className="fs-5">{member.name}</div>
            <div className="text-nowrap">{member.role}</div>
        </div>
    );
};
