import { Layout } from '../components/layout/Layout';
import { OurGamesSection } from '../sections/OurGamesSection';
import { AboutSection } from '../sections/AboutSection';
import { PublishingSection } from '../sections/PublishingSection';
import { CareersSection } from '../sections/CareersSection';
import { ContactsSection } from '../sections/ContactsSection';
import { PromoSection } from '../sections/PromoSection';

export const HomePage = () => {
    return (
        <Layout logoHidden={false}>
            <PromoSection />
            <OurGamesSection />
            <AboutSection />
            <PublishingSection />
            <CareersSection />
            <ContactsSection />
        </Layout>
    )
}
