import { ProgectRegistrationLinks } from '../../constants/projects';
import { Caption } from './Caption';
import { strings } from '../../constants/strings';
import { services } from '../../constants/services';
import { ServiceIconLink } from './ServiceIconLink';

type StoreLinksProps = {
    links: ProgectRegistrationLinks,
};

export const RegistrationLinks = ({ links: { googlePlay, appStore, steam } }: StoreLinksProps) => {
    return (
        <div className="d-flex align-items-center">
            <Caption>{strings.registration}</Caption>
            <div className="d-flex fs-3">
                {googlePlay && <ServiceIconLink href={googlePlay} service={services.googlePlay} />}
                {appStore && <ServiceIconLink href={appStore} service={services.appStore} />}
                {steam && <ServiceIconLink href={steam} service={services.steam} />}
            </div>
        </div>
    );
};

