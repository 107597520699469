import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Anchor } from '../components/common/Anchor';
import { SectionHeader } from '../components/common/SectionHeader';
import { strings } from '../constants/strings';

export const CareersSection = () => {
    return (
        <section>
            <Anchor id="careers" />
            <div className="container my-5">
                <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce>
                    <SectionHeader>{strings.careersHeader}</SectionHeader>
                    <div className="col-md-6 offset-md-3">
                        <p>{strings.careersText}</p>
                    </div>
                </AnimationOnScroll>
            </div>
        </section>
    )
}
