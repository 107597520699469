import { Layout } from '../components/layout/Layout'
import PrivacyPolicy from '../sections/privacyPolicy'

export const PrivacyPage = () => {
    return (
        <Layout>
            <PrivacyPolicy />
        </Layout>
    )
}
