import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { ProjectList } from '../components/ProjectList';
import { SectionHeader } from '../components/common/SectionHeader';
import { strings } from '../constants/strings';
import { Anchor } from '../components/common/Anchor';

export const OurGamesSection = () => {
    return (
        <section className="position-relative">
            <Anchor id="games" />
            <div className="main-top-gradient"></div>
            <div className="container my-5">
                <AnimationOnScroll animateIn="animate__fadeInUp" animateOnce>
                    <SectionHeader>{strings.ourGamesHeader}</SectionHeader>
                </AnimationOnScroll>
                <ProjectList />
            </div>
        </section>
    )
}
