import { useParams } from 'react-router-dom';
import { Layout } from '../components/layout/Layout';
import { ProjectDetails } from '../components/ProjectDetails';
import { projects } from '../constants/projects';


type ProjectPageRouterProps = {
    project: string
}

export const ProjectPage = () => {
    const params = useParams<ProjectPageRouterProps>()
    const project = projects().find(project => project.path === params.project)
    return (
        <Layout>
            <div className="container my-5 z-index-1">
                {project && <ProjectDetails project={project} />}
            </div>
        </Layout>
    )
}
