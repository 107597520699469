import { Layout } from '../../components/layout/Layout'

export const MimicryOfferPage = () => {
    return (
        <Layout>
            <div className="container my-5">
                <Offer />
            </div>
        </Layout>
    )
}

const Offer = () => (
    <div style={{ whiteSpace: "pre-wrap" }}>
        {`
ПУБЛИЧНАЯ ОФЕРТА (обновлено 27.04.2023)

1. ОБЩЕЕ ОПИСАНИЕ СЕРВИСА 

1.1. Сервис «Внутриигровой магазин Mimicry» является платформой, предоставляющей функционал для приобретения внутриигровых объектов (далее – «Магазин Mimicry»). Магазин Mimicry предоставляется внутри игры через платежный сервис (далее - «Партнер»). 

1.2. Настоящая публичная Оферта (далее – «Оферта») публикуется по адресу: https://euphoriagames.ru/mimicry/offer. 

1.3. Магазин Mimicry оперируется и управляется ИП Гусаков Анатолий Николаевич (ИНН: 402709380580) (далее - «Euphoria Games»). 

1.4. Магазин Mimicry предоставляет любому дееспособному физическому лицу-пользователю Игры (далее – «Пользователь») возможность совершать покупки для получения услуг доступа к дополнительному программному обеспечению (внутриигровым объектам) мобильной игр для устройств на платформe Android под общим названиями «Mimicry» (далее – «Игра») с помощью приобретения услуги по осуществлению доступа Пользователя к дополнительному программному обеспечению (внутриигровым объектам) Игры. 

1.5. Настоящая Оферта применяется только к возможности совершения возмездных покупок в Игре с использованием Партнера. Процессы сбора и обработки персональных данных Пользователей, а также сбора и обработки файлов cookie регулируются Политикой Конфиденциальности, доступной по ссылке: https://euphoriagames.ru/mimicry/privacy. 

В части предоставления доступа к дополнительному программному обеспечению (внутриигровым объектам) Игры Euphoria Games выступает в правообладателя Игры. 

1.6. Пользуясь Магазином Mimicry и/или совершая любую покупку в данном магазине, Пользователь соглашается с условиями следующих документов: 

1.6.1. Оферты; 

1.6.2. Политики конфиденциальности. 

1.7. При использовании Магазина Mimicry способами, предполагающими участие в работе партнеров и других третьих лиц, Пользователь обязан также соблюдать условия (пользовательские соглашения, договоры и т.д.) таких третьих лиц. 

1.8. Оферта на странице https://euphoriagames.ru/mimicry/offer представлена в самой актуальной редакции. Со временем мы можем вносить изменения в одностороннем порядке. Все изменения вступают в силу с момента их публикации на этой странице, однако к каждой конкретной транзакции (покупке) будут применяться условия версии Оферты, действовавшей на момент совершения транзакции. Мы приложим максимальные усилия, чтобы организовать дополнительные способы оповещения Пользователей об опубликовании изменений в Оферте, но просим вас регулярно проверять наличие последней версии на Сайте самостоятельно. 

2. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ 

2.1. «Аккаунт» - игровой аккаунт Пользователя в Игре; 

2.2. «Доступ» – право использования Контента в игровом процессе; 

2.3. «Заказ» – запрос Пользователя на покупку Доступа, выбранного при помощи функционала Игры; 

2.4. «ID Пользователя» - уникальный идентификатор (сочетание букв и цифр), обеспечивающий авторизацию конкретного пользователя Игры, и позволяющий приобретать Контент (как определено ниже) в соответствии с условиями настоящей Оферты; 

2.5. «Контент» – дополнительное программное обеспечение (внутриигровые объекты), перечень и описание которых представлены на Сайте, Доступ к которым Euphoria Games предлагает приобрести на Сайте; 

2.6. «Партнер» – третье лицо, которое при помощи сайта Партнера, программ для ЭВМ, сервисов или иного механизма обеспечивает Пользователю возможность совершать платежи для Доступа к Контенту; 

2.7. «Стороны» – совместное упоминание Euphoria Games и Пользователя; 

2.8. «Правообладатель» - компания Euphoria Games, являющаяся создателем и правообладателем Игры и Контента. 

3. ЗАКЛЮЧЕНИЕ ДОГОВОРА 

3.1. Euphoria Games предоставляет любому Пользователю Оферту. Во избежание противоречий Euphoria Games подтверждает, что Оферта является публичной в значении ст. 437 Гражданского кодекса Российской Федерации (далее – «ГК РФ»). 

3.2. Договор на основании Оферты заключается в порядке, предусмотренном п. 3 ст. 438 ГК РФ, путем размещения Euphoria Games Оферты на Сайте и акцепта Оферты Пользователем путем выбора Заказа в Игре и оплаты полной стоимости Заказа. Договор считается заключенным в момент успешной оплаты Заказа Пользователем. Акцепт Оферты и заключение договора не требует дополнительных действий и формальностей (подписания документа на бумажном носителе, подписания с использованием электронной подписи, обмена электронными сообщениями и т.п.). 

3.3. Договор, заключаемый на основании настоящей Оферты, является договором присоединения в соответствии со ст. 428 ГК РФ, к которому Пользователь присоединяется без каких-либо исключений и/или оговорок. 

3.4. Пользователь подтверждает, что достиг совершеннолетия по законодательству своей страны и обладает достаточной дееспособностью для совершения сделок по заключению и исполнению Договора на основании настоящей Оферты. Если Пользователь не достиг возраста полной дееспособности в соответствии с законодательством своей страны, ему надлежит ознакомиться с настоящей Офертой и прочими документами, размещенными на Сайте, с помощью родителя или законного представителя. В этом случае родитель или законный представитель акцептует Оферту от имени несовершеннолетнего лица. 

3.5. В пределах, разрешенных законом, Euphoria Games не несет какой-либо ответственности за любые действия, которые могут быть совершены несовершеннолетними без разрешения его родителей или законных представителей. За все действия несовершеннолетних несут ответственность их родители или законные представители. 

Euphoria Games оставляет за собой право ограничить доступ к Магазину Mimicry или к Контенту в зависимости от возраста, и тогда несовершеннолетним лицам до определенного возраста может быть разрешен доступ к Магазину Mimicry и к Контенту только с согласия их родителей или законных представителей. 

4. ПОРЯДОК ОФОРМЛЕНИЯ ЗАКАЗА 

4.1. Для приобретения Доступа Пользователю необходимо совершить последовательно следующие действия: 

4.1.1. пройти авторизацию в Игре с использованием ID Пользователя; 

4.1.2. выбрать интересующий товар и нажать кнопку оплаты, тем самым оформив Заказ;

4.1.3. оплатить стоимость Заказа любым способом, предусмотренным настоящей Офертой и функционалом Партнера. 

4.1.4. вернуться в Игру и дождаться получения Доступа.

4.2. При совершении действий, указанных в п. 4.1. Оферты, Пользователь подтверждает, что Euphoria Games довел до него информацию о Доступе и Контенте, в том числе, но не ограничиваясь, об описании Контента, порядке получения (активации) Доступа, о сайте/сервисе, через который возможно осуществление Доступа, о его полной стоимости. 

4.3. Пользователь гарантирует и несет ответственность за корректность и актуальность указанных в Заказе персональных данных, которые необходимы для надлежащего исполнения Договора. 

4.4. Пользователь принимает и соглашается с тем, что Euphoria Games не несет ответственности за ненадлежащее исполнение настоящей Оферты, если такое ненадлежащее исполнение вызвано нарушением Пользователем обязательств, предусмотренных п. 4.3. настоящей Оферты. 

4.4. В случае, если в государстве, резидентом которого является Пользователь, приобретение права использования Контента в соответствии с условиями настоящей Оферты является объектом налогообложения, Пользователь обязуется самостоятельно и за свой счет исчислить и уплатить необходимые суммы налогов, сборов, пошлин. 

5. СТОИМОСТЬ И ПОРЯДОК ОПЛАТЫ 

5.1. Стоимость услуг по предоставлению Доступа определяется Euphoria Games в одностороннем порядке и указывается в Игре. Цена указывается в рублях Российской Федерации. Цена за Доступ указывается в отношении каждой единицы Контента в Игре. 

5.2. Стоимость может быть изменена Euphoria Games в одностороннем порядке. 

5.3. Покупка осуществляется по собственной воле Пользователя. Все покупки, совершенные Пользователем, считаются совершенными им самостоятельно, независимо от того, кто инициировал покупку. 

5.4. Пользователь обязан оплатить полную стоимость Доступа единовременным платежом. 

5.5. Euphoria Games сохраняет за собой право предоставлять Пользователю скидки и доступ к акциям, а также определять и изменять срок действия и условия применения таких скидок и акций по своему усмотрению. 

5.6. Пользователь производит оплату при помощи функционала Игры и сервисов Партнера согласно инструкциям, которые предоставляются Euphoria Games и его Партнерами в ходе оформления Заказа.

5.7. Пользователям запрещено осуществлять любую мошенническую/незаконную деятельность, которая может привести к ложной оплате.

5.8. Euphoria Games сохраняет за собой право отказать Пользователю в предоставлении Доступа до момента получения Euphoria Games подтверждения полной оплаты.

5.9. В случае возникновения технических сложностей при оплате Пользователь вправе обратиться к поддержке Euphoria Games по адресу электронной почты: euphoriagamesrussia@gmail.com.

5.10. Пользователь дает свое согласие на получение от Партнера электронного кассового чека после совершения покупки на адрес электронной почты, указанный Пользователем в Заказе.

6. ПРЕДОСТАВЛЕНИЕ ДОСТУПА

6.1. Euphoria Games обязуется предоставить Пользователю право использования Контента после полной оплаты Заказа и зачисления денежных средств на счет Euphoria Games. Стоит учесть, что время обработки Заказа и получения средств может занять до 3 рабочих дней с момента фактической инициации оплаты Пользователем.

6.2. В случае возникновения технических неполадок указанный в пункте 6.1 срок может быть увеличен Euphoria Games на срок, необходимый для устранения таких технических неполадок. Как только технические неполадки будут устранены, Euphoria Games предоставляет Пользователю Доступ.

6.3. Доступ осуществляется в следующем порядке:

6.3.1. Пользователь переходит в Игру;

6.3.2. Пользователь проходит авторизацию в ранее созданном игровом аккаунте;

6.3.3. Пользователь получает Доступ к Контенту и имеет возможность использовать Контент при пользовании Игрой для личных, семейных и иных аналогичных целей, не связанных с предпринимательской деятельностью;

6.4. Euphoria Games предоставляет Доступ Пользователю путем активации в Аккаунте Пользователя данных для использования Контента. Обязательство Euphoria Games по предоставлению Доступа Пользователю считается исполненным с момента такой активации и не зависит от того, ознакомился ли Пользователь с предоставленной Euphoria Games информацией и воспользовался ли он Контентом.

6.5. Зона ответственности Euphoria Games ограничивается предоставлением Пользователю инструментов для получения Доступа к Контенту. Euphoria Games не несет ответственности за технические возможности устройств Пользователя, стабильности сетей связи, доступности Контента, за которую несет ответственность третье лицо.

6.6. В случае нарушения порядка предоставления Доступа, технических сбоев при предоставлении Доступа или в иных аналогичных случаях, связанных с процедурой предоставления Доступа, Пользователь сообщает об этом службе поддержки Euphoria Games по адресу электронной почты: euphoriagamesrussia@gmail.com.

7. ВОЗВРАТ ЗАКАЗА

7.1. Пользователь вправе отказаться от Заказа до момента оплаты Доступа к Контенту.

7.2. Отказ от Заказа после покупки доступа к Контенту невозможен, поскольку с момента оплаты и предоставления Доступа у Пользователя возникает возможность использовать Контент и контроль за использованием либо неиспользованием Контента со стороны Euphoria Games невозможен.

7.3. Если возврат денежных средств осуществляется в связи с невозможностью исполнения Euphoria Games своих обязательств, то денежные средства направляются на банковский счет, указанный Пользователем при оформлении Заказа.

7.4. Пользователь соглашается с тем, что возврат денежных средств может осуществляться Партнерами, действующими по поручению Euphoria Games, при этом Пользователь обязан принять исполнение, предложенное таким третьим лицом.

8. ОТВЕТСТВЕННОСТЬ И ПРАВА СТОРОН

8.1. На основании настоящей Оферты Пользователь получает право использовать Контент только в личных, семейных и иных аналогичных целях, не связанных с предпринимательской деятельностью. Пользователь осознает, что использование Контента в предпринимательских целях может быть квалифицировано как нарушение исключительных прав Euphoria Games на Контент.

8.2. Если Пользователь предоставляет третьим лицам доступ к своему Аккаунту, ID Пользователя, Пользователь самостоятельно несет ответственность за действия таких третьих лиц.

8.3. Пользователь несет ответственность за любые нарушения Оферты, в том числе предусмотренные применимым законодательством, а также за все последствия таких нарушений (включая любые убытки, которые могут понести Euphoria Games и третьи лица).

8.4. Euphoria Games несет ответственность за исполнение обязательств по предоставлению Пользователю Доступа в согласованные сроки.

8.5. Euphoria Games не несет ответственности за ненадлежащее использование Пользователем Доступа и Контента.

8.6. Поскольку Сайт или Игра могут обновляться, и к ним добавляются новые функциональные возможности, в этой связи форма, характер или перечень Контента могут время от времени меняться без предварительного уведомления Пользователей.

8.7. Ответственность за качество и стабильность работы сервисов Партнера несет Партнер. В случае возникновения вопросов по указанным обстоятельствам Пользователь должен направить их в адрес Партнера.

8.8. Euphoria Games и Партнер не отвечают за убытки Пользователя, возникшие в результате: неправильного заполнения Заказа, в том числе неправильного указания Персональных данных; неправомерных действий Пользователя; неправомерных действий третьих лиц.

8.9. Стороны освобождаются от ответственности за частичное или полное неисполнение обязательств по Договору, если это неисполнение явилось следствием обстоятельств непреодолимой силы, возникших после заключения Договора в результате событий чрезвычайного характера, которые Сторона не могла ни предвидеть, ни предотвратить разумными мерами. К обстоятельствам непреодолимой силы, в частности, относятся: выход из строя оборудования, необходимого для стабильной работы Сайта; совершение DOS/DDOS-атак или иных хакерских атак на Сайт; действия и решения государственных органов власти (издание нормативных, распорядительных или правоприменительных актов, в результате применения которых дальнейшее функционирование Сайта, а также предоставление Доступа станут неправомерными); сбои, возникающие в телекоммуникационных и энергетических сетях, действие вредоносных программ, а также недобросовестные действия третьих лиц, сложности дорожного движения в день доставки, а также несанкционированный доступ и/или выведение из строя программного и/или аппаратного комплекса каждой из Сторон.

8.10. Сторона, ссылающаяся на обстоятельства непреодолимой силы, обязана в течение 5 (пяти) рабочих дней проинформировать другую Сторону о наступлении подобных обстоятельств. Пользователь направляет такое уведомление путем обращения в поддержку Euphoria Games по адресу электронной почты: euphoriagamesrussia@gmail.com. Euphoria Games уведомляет Пользователя о таких обстоятельствах при помощи функционала Сайта.

8.11. В случае возникновения обстоятельств непреодолимой силы срок исполнения обязательств по Договору продлевается соразмерно времени, в течение которого действуют такие обстоятельства и их последствия. В случае, если действие обстоятельств непреодолимой силы, на которые ссылается одна из Сторон, продолжается более чем 60 (шестьдесят) дней, другая Сторона вправе в одностороннем порядке расторгнуть Договор.

9. ПОРЯДОК РАЗРЕШЕНИЯ СПОРОВ И ПРИМЕНИМОЕ ПРАВО

9.1. Настоящая Оферта, порядок заключения Договора на основе Оферты, сам договор и любые внедоговорные обязательства, возникающие из него или в связи с ним, условия признания договора или его частей ничтожными, недействительными, незаключенными, последствия такого признания, все аналогичные вопросы регулируются законодательством Российской Федерации и толкуются в соответствии с ним.

9.2. Стороны соглашаются в первую очередь попытаться разрешить споры путем переговоров. Пользователи должны связаться со службой поддержки по адресу электронной почты: euphoriagamesrussia@gmail.com., указав, что этот вопрос должен быть доведен до сведения юридической службы Euphoria Games. Юридическая служба Euphoria Games должна предоставить письменный ответ в течение 10 (десяти) рабочих дней через средства связи Пользователя, указанные в запросе, до тех пор, пока вопрос не будет разрешен.

9.3. Если спор не может быть разрешен путем переговоров, судебное разбирательство осуществляется в соответствии с законодательством Российской Федерации..

10. ПРОЧИЕ УСЛОВИЯ

10.1. Euphoria Games оставляет за собой право изменять условия настоящей Оферты и всех её неотъемлемых частей без согласования с Пользователем с уведомлением последнего посредством размещения на своем Сайте новой редакции Оферты и/или какой-либо её неотъемлемой части, подвергшейся изменениям. При этом Пользователь самостоятельно несет риски, связанные с тем, что Пользователь не ознакомился с новой редакцией Оферты и/или какой-либо ее части. Новая редакция Оферты и/или какой-либо ее неотъемлемой части вступает в силу с момента опубликования на Сайте.

10.2. Недействительность какого-либо положения настоящей Оферты не влечет за собой недействительность остальных положений.

10.3. Если одно или несколько положений Оферты будут признаны недействительными вступившим в законную силу решением суда по спору между Пользователем и Euphoria Games, остальные положения Оферты останутся в силе для Пользователя и Euphoria Games.

10.4. Если Euphoria Games не может своевременно обеспечить строгое выполнение условий Оферты, это никоим образом не должно толковаться как отказ Euphoria Games от прав в отношении прошлых или будущих обязательств.

10.5. Euphoria Games вправе передавать (уступать) какое-либо или все права и/или обязательства в отношение Пользователя какой-либо третьей стороне. Пользователь не вправе передавать (уступать) какое-либо или все права и/или обязательства из настоящей Оферты какой-либо третьей стороне без предварительного согласия Euphoria Games.
`}
    </div>
)
