import React from "react";
import { faStar, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Project } from "../constants/projects";

type Props = {
    project: Project
}

export const GameStat = ({ project }: Props) => {
    return (
        <>
            <span>
                <FontAwesomeIcon icon={faStar} color="#ddbb00" /> {project.rating}
            </span>
            <span className="ms-3">
                <FontAwesomeIcon icon={faDownload} /> {project.installs}
            </span>
        </>
    )
}
